import React from 'react'
import { avatarFullPath, fullname, imageErrorHandler, phoneSplitter } from '../utils/helpers'
import { Button, Tag } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
const User = ({user, onDelete}) => {
    const history = useHistory()
    return (
        <div style={style.flex}>
            <img onError={(e) => imageErrorHandler(e)} style={style.img} src={avatarFullPath(user.avatar)} alt="" />
            <div style={style.content}>
                <div style={{fontWeight: 'bold', color: '#333'}}> {fullname(user.name, user.lastname)} </div>
                <div style={{fontWeight: 300, color: '#333'}}> {user.email} /  {phoneSplitter(user.phone)} </div>
                <div style={{fontWeight: 300, color: '#333'}}> {user.about} </div>
                <div>
                    {user.categories.map((category, index) => <Tag color="purple" key={index}> {category.title} </Tag>)}
                </div>
            </div>
            <div style={style.actions}>
                <Button type="primary" onClick={() => history.push({pathname: '/app/kullanici-islemi', search: `?userId=${user.id}` })} style={{marginRight: 10, backgroundColor: '#5887FF', border: 'none'}} shape="circle" icon={<EditOutlined />} />
                <Button type="danger" onClick={() => onDelete(user.id)} shape="circle" icon={<DeleteOutlined />} />
            </div>
        </div>
    )
}
const style = {
    flex: {
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        flex: '1 1 auto',
        padding: '15px 20px',
        borderBottom: '1px solid #ccc'
    },
    img: {
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        objectFit: 'cover',
        flex: '0 0 50px',
        height: 50,
        width: 50,
        borderRadius:'50%',
        alignSelf: 'flex-start'
    },
    content: {
        marginLeft: 10,
        display: 'flex',
        flex: 1,
        flexFlow: 'column nowrap'
    },
    actions: {
        display: 'flex',
        flexFlow: 'row nowrap'
    }
}
export default User
