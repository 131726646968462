import React, { useCallback } from "react"
import Cookies from "js-cookie"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "../../axios"
import UserMain from "./UserMain"
import { set_sectors } from '../../redux/ducks/user';
const Main = () => {
    const { role } = useSelector(state => state.user)
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const reqs = useCallback(() => Promise.all([getUser(),getCategories()]),[])
    useEffect(() => {
        reqs()
        // eslint-disable-next-line
    }, [role])
    useEffect(() => {
        async function getSectors() {
            const { data: { data: { items } } } = await axios.get('sectors')
            dispatch(set_sectors(items))
        }
        getSectors();
    // eslint-disable-next-line
    }, [])
    const getUser = async () => {
        try {
            const { data: { data } } = await axios.get("/me")
            const _data = {
                ...data,
                avatar: `${process.env.REACT_APP_BASE_URL}/media/picture/${data.avatar}`
            }
            const { roles } = Cookies.getJSON()
            !roles && Cookies.set("roles", data.roles[0])
            dispatch({ type: "SET_USER", payload: _data })
            dispatch({type: "SET_USER_ROLE",payload: roles})
        } catch (error) {
            console.log(error)
        }
    }
    const getCategories = async () => {
        try {
          const { data: { data } } = await axios.get("categories")
          dispatch({ type: "SET_ALL_CATEGORIES", payload: data })
        } catch (error) {
          console.log(error)
        }
    }
    
    return <UserMain />

}
export default Main;
