import axios from "axios";
import Cookies from "js-cookie"
const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_URL
axiosInstance.interceptors.request.use(function (config) {
  const token = Cookies.get("token")
  if (token) config.headers.Authorization = `Bearer ${token}`

  return config;
});
axiosInstance.interceptors.response.use(response => {
  return response;
}, async (error) => {
    const { refresh_token } = Cookies.getJSON()
    const { response: { status, data: { msg } } } = error;
    const originalRequest = error.config;
    if(!refresh_token && status === 401) {
      Cookies.remove("token", { domain: process.env.REACT_APP_WILD_CARD })
      window.location.pathname = `/`;
    }
    else if (status === 401 && !originalRequest._retry && msg === 'Token has expired') {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      Cookies.set('token', access_token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosInstance(originalRequest);
    } 
    return Promise.reject(error);
  }
);
export default axiosInstance;
const refreshAccessToken = async () => {
  const { refresh_token } =  Cookies.getJSON()
  const data = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/refresh`, {
    method:'PATCH',
    headers:{"Authorization": `Bearer ${refresh_token}`}
  }).then(res => res.json()).then(res => res).catch(err => err)
  return data.access_token;
}
