import styled from "styled-components"
import { FileSearchOutlined } from "@ant-design/icons"
const NoDataContainer = styled.div`
display:flex;
flex-flow:column nowrap;
align-items:center;
justify-content:center;
height:250px;
`
const NoDataText = styled.div`
font-size:25px;
color:#000;
font-weight:700;
margin-top:10px;
text-align: center;
`
export const NoData = ({title}) => {
    return (
        <NoDataContainer>
            <FileSearchOutlined style={{fontSize: 80}} />
            <NoDataText>{title}</NoDataText>
        </NoDataContainer>
    )
}
NoData.defaultProps = {
    title: 'Üzgünüz.Aradığınızla ilgili bir şey yok.'
}