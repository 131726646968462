import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import styled from "styled-components"
import moment from 'moment'
import { useSelector } from "react-redux"
require('react-big-calendar/lib/css/react-big-calendar.css');
const localizer = momentLocalizer(moment)
const CalendarWrapper = styled.div`
padding:15px;
background:#fff!important;
`
const messages = {
    allDay: 'TÜM GÜN',
    previous: 'ÖNCEKİ',
    next: 'SONRAKİ',
    today: 'BUGÜN',
    month: 'AY',
    week: 'HAFTA',
    day: 'GÜN',
    agenda: 'TAKVİM',
    date: 'TARİH',
    time: 'SAAT',
    event: 'ETKİNLİK',
};
const MyCalendar = ({onSelectSlot,onSelectEvent,...rest}) => {
    const { role } = useSelector(state => state.user)
    return (
        <CalendarWrapper>
            <Calendar
                onSelectSlot={(val) => onSelectSlot(val)}
                selectable={role === "MENTOR"}
                scrollToTime={new Date(1970, 1, 1, 6)}
                localizer={localizer}
                {...rest}
                style={{height: "calc(100vh - 353px)"}}
                startAccessor="start_date"
                views={["week"]}
                messages={messages}
                onSelectEvent={(event) => onSelectEvent(event)}
                endAccessor="end_date"
                step={30}
                timeslots={1}
                titleAccessor="title"
                defaultView={Views.WEEK}
                defaultDate={new Date()}
            />
        </CalendarWrapper>
    )
}
export default MyCalendar;