import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "../axios"
import Header from "../components/Header";
import InnerHeader from "../components/InnerHeader"
import Footer from "../components/Footer";
import Cookies from "js-cookie"
import { Col } from 'antd'
const Contracts = () => {
    const { slug } = useParams();
    const [item, setItem] = useState("")
    useEffect(() => {
        window.scrollTo(0,0)
        async function getItemWithSlug() {
            try {
                const { data } = await axios.get(`/page/${slug}`)
                setItem(data.data)
                const { title } = data.data;
                document.title = `Kaviyer | ${title}`;
            } catch (error) {
                console.log(error)
            }
        }
        getItemWithSlug()
        return () => {
            document.title = "Kaviyer"
        }
    }, [slug])
    return (
        <>
            {!Cookies.get('token') ? <Header /> : <InnerHeader />}
            <div style={{ marginTop: '60px', padding: '24px', overflow: 'hidden' }}>
                <Col>
                    <h1 style={{ padding: "0px 10px" }}>
                        {item.title}
                    </h1>
                </Col>
                <Col>
                    <div style={{ padding: "0px 10px" }} dangerouslySetInnerHTML={{__html: `${item.content}`}} />
                </Col>
            </div>
            <Footer />
        </>
    )
}

export default Contracts;
