/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import InnerWrapper from '../components/InnerWrapper'
import {
    Form,
    Input,
    Button,
    notification,
    Row,
    Col,
    Upload,
    Cascader,
    Modal,
    Checkbox,
    Select
} from "antd";
import {
    MailOutlined,
    UserOutlined,
    LoadingOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../axios";
import { getBase64, beforeUpload, dummyRequest } from "../utils/upload.js";
import { phoneRules, avatarFullPath } from "../utils/helpers";
import SubSector from "../components/SubSector"
const settings = {
    xxl: 12,
    xl: 12,
    lg: 12,
    sm: 24,
    md: 12,
    xs: 24,
};
const { Option } = Select;
const SubuserOperation = ({ disabled = false }) => {
    const [userId, setUserId] = useState("")
    const [pageTitle, setPageTitle] = useState("Firma Yeni Danışan Kaydı")
    const [buttonTitle, setButtonTitle] = useState("Ekle")
    const history = useHistory()
    const { search } = useLocation()
    const form = useRef()
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailrequired, setEmailConfirmation] = useState("")
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [avatar, setAvatar] = useState("");
    const [loadingAvatar, setLoadingAvatar] = useState(false);

    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [gender, setGender] = useState("");
    const [disability, setDisability] = useState("");
    const [education, setEducation] = useState("");
    const [education_detail, setEducationDetail] = useState("");
    const [educationText, setEducationText] = useState("Eğitim Detay");

    const [linkedin, setLinkedin] = useState("");
    const [about, setAbout] = useState("");
    const [business_in_role, setBusinessinRole] = useState("")
    const [globalSectors, setGlobalSectors] = useState([])
    const [sectors, setSectors] = useState([])
    const [kvkk, setKvkk] = useState(false)
    const [kvkkText, setKvkkText] = useState(null)
    const [showKvkk, setShowKvkk] = useState(false)

    useEffect(() => {
        getCities();
        getContract()
        getSectors();
    }, []);

    const getContract = async () => {
        try {
            const { data: { data: { content } } } = await axios.get(`/contract/kvkk`)
            setKvkkText(content)
        } catch (error) {

        }
    }

    const getSectors = async () => {
        try {
            const { data: { data: { items } } } = await axios.get("sectors");
            const { data: { data } } = await axios.get('/subsectors')
            const localeSector = items.map(s => ({ ...s, children: [] }))
            setSectors(localeSector.map(sector => ({ ...sector, children: data.items.filter(s => s.sector.id === sector.id) })));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        async function getUser() {
            if (!search) return
            setPageTitle('Kullanıcı Güncelleme')
            setButtonTitle('Güncelle')
            try {
                const _userId = search.replace(/\?userId=/g, "")
                setUserId(_userId)
                const { data: { data } } = await axios(`/business-users/${_userId}`)
                const { about, linkedin, address, name, lastname, email, gender, disability, city, district, phone, education, education_detail, avatar, business_in_role, user_sectors } = data
                form.current.setFieldsValue({
                    gender,
                    about,
                    address,
                    linkedin,
                    disability,
                    name,
                    lastname,
                    phone,
                    education,
                    education_detail,
                    email,
                    email_required: email,
                    location: [city.id, district.id],
                    avatar: avatarFullPath(avatar),
                    business_in_role,
                })
                setAddress(address)
                setAbout(about)
                setGlobalSectors(user_sectors && user_sectors.length > 0 ? user_sectors.map(s => ({...s, sub_sector: s.sub_sector.map(x => x.id)})) : [])
                setName(name)
                setLastname(lastname)
                setEmail(email)
                setEmailConfirmation(email)
                setCity(city.id)
                setDistrict(district.id)
                setPhone(phone)
                setEducation(education)
                setEducationDetail(education_detail)
                setGender(gender)
                setDisability(disability)
                setAvatar(avatarFullPath(avatar))
                setBusinessinRole(business_in_role)
            } catch (error) {
                console.log(error)
                goBack()
            }
        }
        getUser()
    }, [search])
    const goBack = () => history.push('/app/kullanicilar')
    const getCities = async () => {
        try {
            const {
                data: {
                    data: { items },
                },
            } = await axios.get("cities", { params: { districts: true } });
            setCities(items);
        } catch (error) {
            console.log(error);
        }
    };

    const onChangeLocation = (e) => {
        const [city, district] = e;
        setCity(city);
        setDistrict(district);
    };

    const onChanceGender = (e) => {
        setGender(e);
    };
    const onChangeBusinnesRole = (e) => {
        setBusinessinRole(e);
    };
    const onChangeDisability = (e) => {
        setDisability(e);
    };
    const onChangeEducation = (e) => {
        if (e === "other") {
            setEducation(e)
            setEducationText("Eğitm Bilgileriniz")
        } else {
            setEducation(e);
            setEducationText("Okul ve Bölüm Bilgileriniz")

        }
    };
    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            setLoadingAvatar(true);
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) => {
                setLoadingAvatar(false);
                setAvatar(imageUrl);
            });
        }
    };
    const uploadButton = (
        <div style={{ width: "100%" }}>
            {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Resim Yükle</div>
        </div>
    );
    const onFinish = async () => {
        setLoading(true)
        try {
            const user_sectors = globalSectors.map(g => ({ sector: g.sector.id, sub_sector: g.sub_sector, sector_experience: g.sector_experience }))
            const body = {
                name,
                lastname,
                email,
                avatar: avatar.split(",")[1],
                phone: phone.replace(/\s/g, ""),
                about,
                city,
                district,
                address,
                linkedin,
                user_sectors,
                gender,
                disability,
                education,
                education_detail,
                business_in_role,
            }
            if (!search) await axios.put('business-users/create', body)
            else await axios.patch(`business-users/${userId}/update`, body)
            notification.success({
                message: 'Başarılı',
                duration: 1,
                description:
                    'İşlem başarıyla gerçekleştirilmiştir.Yönlendiriliyorsunuz...',
            });
            setTimeout(() => {
                goBack()
            }, 1000);
        } catch (error) {
            const [whatError] = error.response.data.messages
            notification.error({
                duration: 2,
                message: 'Hata!',
                description:
                    Object.values(whatError)[0],
            });
        } finally {
            setLoading(false)
        }
    }
    return (
        <InnerWrapper>
            <div className="full-height full-height--center pd-15">
                <div className="user-form">
                    <h1>{pageTitle}</h1>
                    <Form ref={form} onFinish={onFinish}>
                        <Row gutter={24}>
                            <Col {...settings}>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            type: "string",
                                            required: true,
                                            message: "Lütfen adınızı giriniz!",
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={disabled}
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        size={"large"}
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        placeholder="Ad"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...settings}>
                                <Form.Item
                                    name="lastname"
                                    rules={[
                                        {
                                            type: "string",
                                            required: true,
                                            message: "Lütfen soyadınızı giriniz!",
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => setLastname(e.target.value)}
                                        disabled={disabled}
                                        value={lastname}
                                        size={"large"}
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        placeholder="Soyad"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...settings}>
                                <Form.Item
                                    name="phone"
                                    rules={phoneRules}
                                >
                                    <Input
                                        disabled={disabled}
                                        maxLength={11}
                                        placeholder="Tel No 05333333333"
                                        size="large"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...settings}>
                                <Form.Item
                                    name="location"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lütfen şehir/ilçe seçiniz.",
                                        },
                                    ]}
                                >
                                    <Cascader
                                        disabled={disabled}
                                        fieldNames={{
                                            label: "name",
                                            value: "id",
                                            children: "districts",
                                        }}
                                        showSearch={{
                                            filter(inputValue, path) {
                                                return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                                            }
                                        }}
                                        placeholder="Şehir/İlçe Seçin"
                                        onChange={onChangeLocation}
                                        size="large"
                                        options={cities}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="business_in_role"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lütfen Yetki Durumunu Seçiniz!",
                                        },
                                    ]}
                                >
                                    <Select onChange={(e) => onChangeBusinnesRole(e)} placeholder="Yetkisi">
                                        <Option value="USER">Danışan</Option>
                                        <Option value="MANAGER">Firma Yetkili Danışan</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: "email",
                                            message: "Lütfen emailinizi kontrol ediniz!",
                                        },
                                        {
                                            required: true,
                                            message: "Lütfen emailinizi giriniz!",
                                        },
                                    ]}
                                >
                                    <Input
                                        onCopy={(e) => {
                                            e.preventDefault()
                                            return false
                                        }}
                                        onPaste={(e) => {
                                            e.preventDefault()
                                            return false
                                        }}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled={disabled}
                                        value={email}
                                        size={"large"}
                                        prefix={<MailOutlined className="site-form-item-icon" />}
                                        placeholder="Email"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="email_required"
                                    dependencies={['email']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lütfen emaili tekrar giriniz!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('email') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Email Eşleşmiyor!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        onCopy={(e) => {
                                            e.preventDefault()
                                            return false
                                        }}
                                        onPaste={(e) => {
                                            e.preventDefault()
                                            return false
                                        }}
                                        disabled={disabled}
                                        value={emailrequired}
                                        onChange={(e) => setEmailConfirmation(e.target.value)}
                                        size={"large"}
                                        prefix={<MailOutlined className="site-form-item-icon" />}
                                        placeholder="Email Tekrar"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="education"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lütfen Eğitim Durumu Seçiniz!",
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={disabled}
                                        onChange={(e) => onChangeEducation(e)}
                                        placeholder="Eğitim Durumu">
                                        <Option value="high_school">Lise</Option>
                                        <Option value="college">Yüksekokul</Option>
                                        <Option value="bachelor_degree">Lisans</Option>
                                        <Option value="master_degree">Yüksek Lisans</Option>
                                        <Option value="phd">Doktora</Option>
                                        <Option value="other">Diğer</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="education_detail"
                                    rules={[
                                        {
                                            type: "string",
                                            required: true,
                                            message: "Lütfen Eğitim Bilgilerini giriniz!",
                                        },
                                    ]}
                                >
                                    <Input
                                        disabled={disabled}
                                        onChange={(e) => setEducationDetail(e.target.value)}
                                        value={education_detail}
                                        placeholder={educationText}
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...settings}>
                                <Form.Item
                                    name="gender"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lütfen Cinsiyet Seçiniz!",
                                        },
                                    ]}
                                >
                                    <Select onChange={(e) => onChanceGender(e)} placeholder="Cinsiyet">
                                        <Option value="female">Kadın</Option>
                                        <Option value="male">Erkek</Option>
                                        <Option value="other">Belirtmek İstemiyorum</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...settings}>
                                <Form.Item
                                    name="disability"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lütfen Engel Durumu Seçiniz!",
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={(e) => onChangeDisability(e)}
                                        placeholder="Bir Engel Durumunuz Var mı? "
                                    >
                                        <Option value="no">Hayır</Option>
                                        <Option value="yes">Evet</Option>
                                        <Option value="other">Belirtmek İstemiyorum</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="address"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Lütfen adres giriniz",
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        size="large"
                                        placeholder="Adres"
                                    ></Input.TextArea>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="about"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Boş Geçilemez",
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        value={about}
                                        onChange={(e) => setAbout(e.target.value)}
                                        placeholder="Kısaca iş hayatınızı, tecrübelerinizi ve aldığınız eğitiml eri anlatır mısınız?"
                                    ></Input.TextArea>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item>
                                    <Input
                                        onChange={(e) => setLinkedin(e.target.value)}
                                        value={linkedin}
                                        placeholder="LinkedIn Profil Url"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item name="globalsector" rules={[{
                                    validator: (_, _a) =>
                                        globalSectors.length > 0 ? Promise.resolve() : Promise.reject(new Error('Lütfen sektör seçiniz')),
                                }]}>
                                    <SubSector items={globalSectors} onChange={(e) => setGlobalSectors(e)} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="avatar"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lütfen resminizi yükleyin.",
                                        },
                                    ]}
                                >
                                    <Upload
                                        style={{ width: "100%" }}
                                        valuePropName={avatar}
                                        listType="picture-card"
                                        value={avatar}
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        customRequest={dummyRequest}
                                        onChange={handleChange}
                                        beforeUpload={beforeUpload}
                                        maxCount={1}
                                    >
                                        {avatar ? (
                                            <img
                                                src={avatar}
                                                alt="avatar"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        ) : (
                                            uploadButton
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="kvkk" valuePropName="checked" rules={[{
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('KVKK metnini onaylamak zorundasınız.')),
                                }]} >
                                    <Checkbox checked={kvkk} onChange={(e) => setKvkk(e.target.checked)}>
                                        Kvkk metnini okudum onaylıyorum. <span onClick={() => setShowKvkk(true)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>Okumak için tıklayınız</span>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        loading={loading}
                                        htmlType="submit"
                                        className="user-form-button"
                                    >
                                        {buttonTitle}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <Modal visible={showKvkk} closable footer={null} centered bodyStyle={{ overflowY: 'scroll' }} onCancel={() => setShowKvkk(false)} title="KVKK Onay Metni">
                <div dangerouslySetInnerHTML={{ __html: `${kvkkText}` }}></div>
            </Modal>
        </InnerWrapper>
    )
}

export default SubuserOperation
/* eslint-disable */