import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.less';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './redux/store.js'
import { ConfigProvider } from 'antd';
import trTR from 'antd/lib/locale/tr_TR';
import moment from 'moment';
import 'moment/locale/tr';
moment.locale('tr');
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={trTR}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
