import React, { useEffect, useState } from "react";
import OuterLayout from "../components/OuterLayout";
import Slider from "../components/Slider";
import st from "../assets/images/landing-1.jpg";
import find from "../assets/images/search-mentor.svg";
import req from "../assets/images/request.svg";
import tar from "../assets/images/target.svg";
import arrow from "../assets/images/circle-arrow.svg";
import { useHistory } from "react-router-dom";
import MentorModal from "../components/MentorModal";
import { Row, Col, Tag, notification, Modal } from "antd";
import { CaretRightFilled } from "@ant-design/icons";
import {
  Landing,
  LandingButton,
  LandingText,
  LandingInner,
  LandingComment,
  LandingImage,
} from "../components/Landing";
import { DocumentCard, SlimMentor } from "../components/DocumentCard";
import {
  LandingDocument,
  LandingDocumentButton,
} from "../components/LandingDocument";
import axios from "../axios";
import { avatarFullPath, fullname, imageErrorHandler } from "../utils/helpers";
import { CommentContent } from "../components/CommentContent";

const settings = {
  xs: 24,
  sm: 24,
  md: 7,
  lg: 7,
  xl: 7,
};
const slideSettings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: false,
};
const _slideSettings = {
  ...slideSettings,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const message =
  'Tüm rehberlerimizi görmek ve rehberlerimiz ile ilgili daha detaylı bilgi almak için, sitemize "Danışan" olarak kayıt olabilirsiniz.';
const Home = () => {
  const [news, setNews] = useState([]);
  const [show, setShow] = useState(false);
  const [mentor, setMentor] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [commentShow, setCommentShow] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  // eslint-disable-next-line
  const [comments, setComments] = useState([]);
  const [mentors, setMentors] = useState([]);
  const history = useHistory();
  const navigate = () => {
    history.push("/kayit");
  };
  const main = [
    {
      first: "HEDEFE ULAŞTIRACAK",
      second: "DOĞRU UZMANI BULMANIN",
      third: "EN KOLAY YOLU!",
    },
    {
      first: "HEDEFE ULAŞTIRACAK",
      second: "DOĞRU UZMANI BULMANIN",
      third: "EN KOLAY YOLU!",
    },
    {
      first: "HEDEFE ULAŞTIRACAK",
      second: "DOĞRU UZMANI BULMANIN",
      third: "EN KOLAY YOLU!",
    },
  ];
  const params = { page: 1, per: 8 };
  const routeHandler = (route) =>
    history.push(`/statik/${route !== "feedbacks" ? route : "yorumlar"}`);
  const getDumbs = async (type) => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await axios.get(`${type}`, { params });
      type === "news"
        ? setNews(items)
        : type === "blogs"
        ? setBlogs(items)
        : setComments(items);
    } catch (error) {
      console.log(error);
    }
  };
  const getFeaturedMentors = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await axios.get("/mentors/featured", { params });
      setMentors(items);
    } catch (error) {
      console.log(error);
    }
  };

  const mentorShower = async (id) => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await axios.get(`mentor/${id}`);
      setMentor(items);
      setShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  const commentHandler = (comment) => {
    setSelectedComment(comment);
    setCommentShow(true)
  }

  useEffect(() => {
    getFeaturedMentors();
    getDumbs("news");
    getDumbs("blogs");
    getDumbs("feedbacks");
    // eslint-disable-next-line
  }, []);

  return (
    <OuterLayout>
      <Slider isImage>
        {main.map((_main, index) => (
          <Landing key={index}>
            <LandingInner>
              <LandingText>{_main.first}</LandingText>
              <LandingText>{_main.second}</LandingText>
              <LandingText>{_main.third}</LandingText>
              {/*<LandingButton>AYRINTILAR <CaretRightFilled style={{ color: "#fff", fontSize: 20 }} /></LandingButton>*/}
            </LandingInner>
          </Landing>
        ))}
      </Slider>
      <div className="how-work">
        <h1>Nasıl Çalışıyor?</h1>
        <Row>
          <Col {...settings}>
            <div className="how-work-wrap">
              <div>
                <img src={find} alt="a" />
                <div>Uygun Mentoru Seçin</div>
              </div>
            </div>
          </Col>
          <Col span={1}>
            <div className="how-work-arrow">
              <img src={arrow} alt="" />
            </div>
          </Col>
          <Col {...settings}>
            <div className="how-work-wrap">
              <div>
                <img src={req} alt="a" />
                <div>Tanışmak için talepte bulunun</div>
              </div>
            </div>
          </Col>
          <Col span={1}>
            <div className="how-work-arrow">
              <img src={arrow} alt="Arrow" />
            </div>
          </Col>
          <Col {...settings}>
            <div className="how-work-wrap">
              <div>
                <img src={tar} alt="a" />
                <div>Hedefinize ulaşın.</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {news.length > 0 && (
        <LandingDocument
          routeHandler={() => routeHandler("haberler")}
          title={"Haber / Duyurular"}
          path={"haber"}
          fullscreen={true}
        >
          <Slider {..._slideSettings}>
            {news.map((_new, index) => (
              <DocumentCard key={index}>
                <img
                  onError={(e) => imageErrorHandler(e)}
                  src={avatarFullPath(_new.pictures[0])}
                  alt="Haber"
                />
                <div className="inner-document-card">
                  <div className="inner-document-title">{_new.title}</div>
                  <div className="inner-document-content">{_new.summary}</div>
                  <div className="inner-document-action">
                    <div> {_new.date} </div>{" "}
                    <LandingDocumentButton
                      onClick={() => history.push(`haberler/${_new.id}/news`)}
                      outline={false}
                    >
                      Devamını Oku{" "}
                      <CaretRightFilled
                        style={{ color: "#fff", fontSize: 20 }}
                      />
                    </LandingDocumentButton>
                  </div>
                </div>
              </DocumentCard>
            ))}
          </Slider>
        </LandingDocument>
      )}
      <LandingImage url={st}>
        <div>
          <h1>SİZ DE KATILIN!</h1>
          <p>
            {" "}
            Hedef ve amaçlarının peşinden giderek başarı odağında ilerlemek
            isteyen Girişimciler...
            <br />
            Rehberlerimiz, bilgi, uzmanlık ve deneyimlerini sizlerle paylaşmak
            için başvurularınızı bekliyor.
            <br />
            Yol arkadaşınız olmaya hazırız!
          </p>
          <LandingButton onClick={() => navigate()}>
            {" "}
            HEMEN BAŞVUR{" "}
          </LandingButton>
        </div>
      </LandingImage>
      {mentors.length > 0 && (
        <LandingDocument
          title={"Öne Çıkan Rehberlerimiz"}
          path={"mentor"}
          fullscreen={true}
          hide={true}
        >
          <Slider {..._slideSettings} slidesToShow={4} slidesToScroll={4}>
            {mentors.map((mentor, index) => (
              <SlimMentor key={index}>
                <img
                  onClick={() => notification.info({ message, duration: 2 })}
                  onError={(e) => imageErrorHandler(e, "user")}
                  src={avatarFullPath(mentor.avatar)}
                  alt="Mentor"
                />
                <div
                  onClick={() => notification.info({ message, duration: 2 })}
                >
                  <label> {fullname(mentor.name, mentor.lastname)} </label>
                  <p> {mentor.about} </p>
                  <label>
                    {" "}
                    {mentor.categories.map((c) => (
                      <Tag key={c.id}> {c.title} </Tag>
                    ))}
                  </label>
                </div>
                <LandingDocumentButton
                  onClick={() => mentorShower(mentor.id)}
                  outline={false}
                >
                  Detaylı İncele{" "}
                  <CaretRightFilled style={{ color: "#fff", fontSize: 20 }} />
                </LandingDocumentButton>
              </SlimMentor>
            ))}
          </Slider>
        </LandingDocument>
      )}

      {blogs.length > 0 && (
        <LandingDocument
          routeHandler={() => routeHandler("bloglar")}
          title={"Blog Yazıları"}
          path={"blog"}
          fullscreen={true}
        >
          <Slider {..._slideSettings}>
            {blogs.map((_blog, index) => (
              <DocumentCard key={index}>
                <img
                  onError={(e) => imageErrorHandler(e)}
                  src={avatarFullPath(_blog.pictures[0])}
                  alt="Haber"
                />
                <div className="inner-document-card">
                  <div className="inner-document-title">{_blog.title}</div>
                  <div className="inner-document-content">{_blog.summary}</div>
                  <div className="inner-document-action">
                    <div> {_blog.date} </div>{" "}
                    <LandingDocumentButton
                      onClick={() => history.push(`bloglar/${_blog.id}/blog`)}
                      outline={false}
                    >
                      Devamını Oku{" "}
                      <CaretRightFilled
                        style={{ color: "#fff", fontSize: 20 }}
                      />
                    </LandingDocumentButton>
                  </div>
                </div>
              </DocumentCard>
            ))}
          </Slider>
        </LandingDocument>
      )}

      {comments.length > 0 && (
        <LandingDocument
          title={"Kullanıcı Yorumları"}
          path={"yorum"}
          routeHandler={() => routeHandler("yorumlar")}
          fullscreen={true}
        >
          <Slider {..._slideSettings}>
            {comments.map((comment, index) => (
              <LandingComment onClick={() => commentHandler(comment)} key={index}>
                <div className="comment-header">
                  <div> {comment.name} </div>
                </div>
                <div className="comment-content">
                  <img
                    onError={(e) => imageErrorHandler(e, "user")}
                    src={avatarFullPath(comment.picture)}
                    alt="Comment"
                  />
                  <div>{comment.description}</div>
                </div>
              </LandingComment>
            ))}
          </Slider>
        </LandingDocument>
      )}
      <Modal
        centered
        closable
        width={400}
        onCancel={() => setShow(false)}
        visible={show}
        footer={null}
      >
        <MentorModal
          show={false}
          closeHandle={(val) => setShow(val)}
          mentor={mentor}
        />
      </Modal>
      <Modal
        centered
        closable
        width={400}
        onCancel={() => setCommentShow(false)}
        visible={commentShow}
        footer={null}
      >
        <CommentContent {...selectedComment} />
      </Modal>
    </OuterLayout>
  );
};
export default Home;
