/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Modal, Form, Row, Col, Input, Button, notification } from 'antd';
import { useParams, useLocation, useHistory } from "react-router-dom"
import axios from "../axios"
import Logo from "../components/FormLogo"
import {
    requiredPassword, minimumCharacter, digitRules,
    uppercase,
    lowercase,
    excludeSpecialCharacters
} from "../utils/helpers";
const MentorActivate = () => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery()
    const [params, setParams] = useState(query.get("first_password") || "")
    const [password, setPassword] = useState("")
    const [password_confimation, setPasswordConfirmation] = useState("")
    const [loading, setLoading] = useState(false)
    const { token } = useParams();
    const history = useHistory()
    const modalTrigger = (type, message) => {
        Modal[type]({
            title: 'Bilgi',
            content: `${message}`,
            onOk() {
                history.push("/giris")
            },
        })
    }
    const activateUser = async () => {
        try {
            const { data: { messages } } = await axios.post(`/auth/verification/${token}`)
            modalTrigger("success", messages[0])
        } catch (error) {
            modalTrigger("error", error.response.data.messages[0])
        } finally {
        }
    }

    const subuserPasswordChange = async () => {
        try {
            const body = {
                password,
                password_confimation
            }
            await axios.post(`/set-password-business-user/${token}`, body)
        } catch (error) {
            notification.error({ message: 'Bir hata oluştu', duration: 1 })
        }
    }
    const onFinish = async () => {
        try {
            await subuserPasswordChange()
            await activateUser()   
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        const isSubUserPasswordReset = query.getAll("first_password").length
        if (isSubUserPasswordReset === 0) activateUser()
    }, [])

    useEffect(() => {
        setParams(query.get("first_password") || "")
    }, [query])
    return (
        <div className="full-height full-height--center pd-15">
            <div className="user-form">
                <Logo />
                {
                    params &&
                    <Form onFinish={onFinish}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        minimumCharacter,
                                        digitRules,
                                        uppercase,
                                        lowercase,
                                        excludeSpecialCharacters,
                                        requiredPassword
                                    ]}
                                >
                                    <Input.Password
                                        onCopy={(e) => {
                                            e.preventDefault()
                                            return false
                                        }}
                                        onPaste={(e) => {
                                            e.preventDefault()
                                            return false
                                        }}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        size={"large"}
                                        placeholder="Şifre"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="password_required"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        requiredPassword,
                                        minimumCharacter,
                                        digitRules,
                                        uppercase,
                                        lowercase,
                                        excludeSpecialCharacters,
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Şifreler Eşleşmiyor!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        onCopy={(e) => {
                                            e.preventDefault()
                                            return false
                                        }}
                                        onPaste={(e) => {
                                            e.preventDefault()
                                            return false
                                        }}
                                        value={password_confimation}
                                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                                        size={"large"}
                                        placeholder="Şifre Tekrar"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button block loading={loading} disabled={loading} type="primary" htmlType="submit">KAYDET</Button>
                            </Col>
                        </Row>
                    </Form>
                }
            </div>
        </div>
    )
}

export default MentorActivate;