import styled from "styled-components"
export const DocumentCard = styled.div`
height:350px;
border-radius:3px;
display:flex;
flex-flow:column nowrap;
img{
    width:100%;
    height:150px;
    object-fit:cover;
}
`
export const SlimMentor = styled.div`
display:flex !important;
flex-flow:column nowrap;
height:350px;
background: #f5f5f5;
justify-content:space-evenly;
align-items:center;
label {
    font-size:23px;
    color:#5887FF;
    font-weight:bold;
    text-align:center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
p {
    font-size:18px;
    color: #000;
    font-weight: 500;
    text-align:center;
    margin-bottom: 0px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
div {
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    justify-content:flex-start;
}
img {
    border-radius:50%;
    width:150px;
    height:150px;
    object-fit:cover;
}
`