import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { LockOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "../axios/index";
import { lowercase, uppercase, excludeSpecialCharacters, minimumCharacter, digitRules } from "../utils/helpers";
import Logo from "../components/FormLogo";
const PasswordReset = () => {
    let history = useHistory()
    const { token } = useParams()
    const onFinish = async () => {
        setLoading(true)
        try {
            const body = {
                password,
                password_confirmation: passwordConfirmation
            }
            await axios.patch(`/auth/password/${token}`, body)
            notification.success({
                message: 'Şifre sıfırlama işlemi başarılı, yönlendiriliyorsunuz..',
                placement: 'topRight',
                duration:2
            })
            setTimeout(() => {
                history.push("/giris")
            }, 2000);
        } catch (err) {
            const message = err?.response?.data?.messages[0] || "Bir hata oluştu.";
            notification.error({
                message,
                placement: 'topRight'
            })
        } finally {
            setLoading(false)
        }
    }
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    return (
        <div className="full-height full-height--center pd-15">
            <div className="user-form">
                <Link to={"/"}> <Logo /> </Link>
                <Form
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen şifrenizi giriniz!',
                            },
                            minimumCharacter,
                            digitRules,
                            uppercase,
                            lowercase,
                            excludeSpecialCharacters,
                        ]}
                    >
                        <Input.Password
                        onCopy={(e)=>{
                            e.preventDefault()
                          return false }}
                          onPaste={(e)=>{
                            e.preventDefault()
                          return false }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            size={"large"}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Şifre"
                        />
                    </Form.Item>
                    <Form.Item
                        name="repassword"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen şifrenizin tekrarını giriniz!',
                            },
                            minimumCharacter,
                            excludeSpecialCharacters,
                            uppercase,
                            lowercase,
                            digitRules,
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                    
                                  return Promise.reject('Şifreniz eşleşmiyor.');
                                },
                              }),
                        ]}
                    >
                        <Input.Password
                        onCopy={(e)=>{
                            e.preventDefault()
                          return false }}
                          onPaste={(e)=>{
                            e.preventDefault()
                          return false }}
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            size={"large"}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Şifre Tekrarı"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" loading={loading} htmlType="submit" className="user-form-button">
                            SIFIRLA
                        </Button>
                    </Form.Item>

                </Form>
            </div>
        </div>
    );
};

export default PasswordReset;
