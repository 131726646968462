import { useEffect, useState } from "react";
import InnerWrapper from "../components/InnerWrapper";
import {
  Map,
  MapContent,
  MapContentWrapper,
  MapContainer,
} from "../components/InnerLanding";
import { MentorContainer } from "../components/Mentor";
import Preloader from "../components/Preloader";
import { Collapse, Button, Select, Modal, Progress, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "../axios";
import { useSelector } from "react-redux";
import { NoData } from "../components/NoData";
import { fullname } from "../utils/helpers";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";

const { Option } = Select;

const RecordList = ({ appointment, records }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [show, setShow] = useState(false);

  const onChange = ({ target: { checked, value } }) => {
    if (checked) {
      setSelected([...selected, value]);
    } else {
      setSelected(selected.filter((item) => item !== value));
    }
  };

  const beatifulTime = (time) => {
    if (!time) return "-";
    const [secs] = time.split(".");
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = secs % 60;
    return `${hours} saat ${minutes} dakika ${seconds} saniye`;
  };

  const downLoad = async () => {
    try {
      setLoading(true);
      await Promise.all([
        ...selected.map(async (item) => {
          try {
            const record = records.find((record) => record.id === item);
            const response = await axios({
              url: `download-record/${appointment.room_id}`, //your url
              method: "POST",
              responseType: "blob", // important
              data: {
                filename: record.path,
              },
              onDownloadProgress: (progressEvent) => {
                setShow(true);
                setPercent(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                );
              },
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            link.setAttribute(
              "download",
              `kaviyer_gorusme_kaydi_${record.path}`
            );
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            notification.error({
              message: "HATA!!!",
              description: "İndirirken bir hata oluştu.",
            });
          }
        }),
      ]);
      setPercent(0);
      setSelected([]);
      setLoading(false);
      setShow(false);
    } catch (error) {
      setShow(false);
      setPercent(0);
      setSelected([]);
      setLoading(false);
    }
  };
  return (
    <>
      <div>
        <Checkbox
          onChange={(e) =>
            setSelected(e.target.checked ? records.map(({ id }) => id) : [])
          }
          checked={selected.length === records.length}
        >
          Tümünü Seç
        </Checkbox>
        <Button
          onClick={() => downLoad()}
          size="small"
          loading={loading}
          disabled={loading || !selected.length}
          icon={<DownloadOutlined />}
        >
          İndir
        </Button>
      </div>
      {records.map((record, index) => (
        <div key={index}>
          <Checkbox
            checked={selected.includes(record.id)}
            onChange={onChange}
            value={record.id}
          >
            Süre: {beatifulTime(record.duration)}
          </Checkbox>
        </div>
      ))}
      <Modal
        title={null}
        footer={null}
        style={{ top: 20 }}
        destroyOnClose
        visible={show}
        closeIcon={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>Seçtikleriniz indirilirken lütfen bekleyin.</h3>
          <Progress type="circle" percent={percent} />
        </div>
      </Modal>
    </>
  );
};
const PanelHeader = ({ appointment, index, recordCount }) => {
  const { role } = useSelector((state) => state.user);
  const { mentor, user, start_date, end_date } = appointment;
  const name = role === "USER" ? mentor.name : user.name;
  const lastname = role === "USER" ? mentor.lastname : user.lastname;
  const info = `${moment(start_date).format("DD MMMM HH:mm")}-${moment(
    end_date
  ).format("DD MMMM HH:mm")}`;

  const count = index + 1;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flex: 1,
        flexWrap: "wrap",
        gap: 2,
      }}
    >
      <div>
        <strong>{count}.</strong>
        <strong
          style={{ fontWeight: "bold", color: "#5887FF", marginRight: 2 }}
        >
          {fullname(name, lastname)}
        </strong>
        kullanıcısı ile
        <strong
          style={{
            color: "#5887FF",
            fontWeight: "bold",
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          {info}
        </strong>
        tarihleri arasında
        <strong
          style={{
            color: "#5887FF",
            fontWeight: "bold",
            marginRight: 2,
            marginLeft: 2,
          }}
        >
          ({recordCount} adet)
        </strong>
        kayıt edilmiş görüşmeniz bulunuyor.
      </div>
    </div>
  );
};

export const Records = () => {
  const { role } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [recorded, setRecorded] = useState(true);

  useEffect(() => {
    const params = {
      role,
      recorded,
    };
    setLoading(true);
    axios
      .get("appointments", { params })
      .then(({ data: { data } }) => {
        const { items } = data;
        setRecords(items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [role, recorded]);

  return (
    <InnerWrapper>
      <MapContainer>
        <MapContent>
          <MapContentWrapper>
            <div>Kaviyer!</div>
            <div style={{ fontSize: 20 }}>
              Buradan görüşmelerinin kayıtlarına ulaşabilirsin.
            </div>
          </MapContentWrapper>
        </MapContent>
        <Map />
      </MapContainer>
      <MentorContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <h1>Görüşme Kayıtlarım</h1>
          <div style={{ width: 200 }}>
            <Select
              disabled={loading}
              onChange={(e) => setRecorded(e)}
              style={{ width: "100%" }}
              value={recorded}
              allowClear
              onClear={() => setRecorded(false)}
              placeholder="Görüşme Filtreleri"
            >
              <Option value={true}>Sadece Kayıtı Olanlar</Option>
            </Select>
          </div>
        </div>
        {loading && <Preloader />}
        {!loading && records.length > 0 && (
          <>
            <Collapse>
              {records.map((record, index) => (
                <Collapse.Panel
                  collapsible={
                    record.records.length === 0 ? "disabled" : "header"
                  }
                  header={
                    <PanelHeader
                      appointment={record}
                      recordCount={record.records.length}
                      index={index}
                    />
                  }
                  key={index}
                >
                  <RecordList appointment={record} records={record.records} />
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
        {!loading && records.length === 0 && (
          <NoData title="Kayıt edilmiş görüşmeleriniz bulunmamaktadır." />
        )}
      </MentorContainer>
    </InnerWrapper>
  );
};
