import Cookies from "js-cookie";

const SET_USER = "SET_USER";
const LOGOUT_USER = "LOGOUT_USER";
const SET_CITIES = "SET_CITIES";
const SET_USER_ROLE = "SET_USER_ROLE";
const SET_SECTORS = "SET_SECTORS";
export const set_user_role = (payload) => ({
    type: SET_USER_ROLE,
    payload
})
export const set_sectors = (payload) => ({
    type: SET_SECTORS,
    payload
})
export const set_user = (payload) => ({
    type: SET_USER,
    payload
})
export const set_cities = (payload) => ({
    type: SET_CITIES,
    payload
})
export const logout_user = () => ({
    type: LOGOUT_USER
})
export const initialState = {
    me: "",
    cities: [],
    sectors: [],
    selectedSectors: [],
    role: Cookies.get("roles") || ""
}
const user = (state = initialState, action) => {
    switch (action.type) {
        case ("SET_USER"):
            return {
                ...state,
                me: action.payload
            };
        case ("SET_SECTORS"):
            return {
                ...state,
                sectors: action.payload
            };
        case ("SET_SELECTED_SECTORS"):
            return {
                ...state,
                selectedSectors: action.payload
            };
        case ("LOGOUT_USER"):
            return {
                ...initialState,
                role: ""
            };
        case ("SET_CITIES"):
            return {
                ...state,
                cities: action.payload
            }
        case ("SET_USER_ROLE"):
            return {
                ...state,
                role: action.payload
            }
        default:
            return state
    }
}
export default user;