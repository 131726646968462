import { useRef, useEffect, useState } from 'react'
import { Form, Button, Col, Select, Modal, InputNumber, Alert } from 'antd'

import axios from '../axios'
const { Option } = Select
const SubSector = ({ onChange, items = [], hide = false, business = false, isUser = false }) => {
  const formV2 = useRef()
  const [sectors, setSectors] = useState([])
  const [selectedSubSectors, setSelectedSubSectors] = useState([])
  const [sectorVisible, setSectorVisible] = useState(false)
  const [_sectors, set_Sectors] = useState('')
  const [globalSectors, setGlobalSectors] = useState(items)
  const [sector_experience, setSectorExperience] = useState('')
  const [error, setError] = useState(false)
  const [sub_sectors, setSub_sectors] = useState([])
  const [errorMsg, setErrMessage] = useState('')
  useEffect(() => {
    setSelectedSubSectors([])
    setSectorExperience('')
    if (formV2.current) {
      formV2.current.setFieldsValue({
        subsectors: [],
        sector_experience: ''
      })
    }
  }, [_sectors])
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false)
      }, 1500)
    }
  }, [error])
  useEffect(() => {
    if (items && items.length > 0) setGlobalSectors(items)
  }, [items])
  useEffect(() => {
    set_Sectors('')
    setSectorExperience('')
    setSelectedSubSectors([])
  }, [sectorVisible])
  useEffect(() => {
    getSectors()
  }, [])
  const getSectors = async () => {
    try {
      const {
        data: {
          data: { items }
        }
      } = await axios.get('sectors')
      const {
        data: { data }
      } = await axios.get('/subsectors')
      setSub_sectors(data.items)
      const localeSector = items.map(s => ({ ...s, children: [] }))
      setSectors(
        localeSector.map(sector => ({ ...sector, children: data.items.filter(s => s.sector.id === sector.id) }))
      )
    } catch (error) {
      console.log(error)
    }
  }
  const onFinishSecond = async () => {
    const sector = {
      sector: sectors.find(({ id }) => id === _sectors),
      sub_sector: selectedSubSectors
    }
    if (!hide) sector.sector_experience = sector_experience
    const currentSectors = [...globalSectors, sector]
    if (globalSectors.length > 0 && globalSectors.some(({ sector: { id } }) => id === sector.sector.id)) {
      setError(true)
      setErrMessage('Bu sektör daha önceden eklenmiş.Lütfen kaldırıp tekrar ekleyiniz.')
      reset()
      return
    } else if (globalSectors.length === 3) {
      setError(true)
      setErrMessage('En Fazla 3 sektör eklenebilir.')
      reset()
      return
    }

    setGlobalSectors(currentSectors)
    reset()
    onChange(currentSectors)
  }
  const reset = () => {
    set_Sectors('')
    setSectorExperience('')
    setSelectedSubSectors([])
    formV2.current.resetFields()
  }
  const closeHandler = id => {
    setGlobalSectors(globalSectors.filter(s => s.sector.id !== id))
    onChange(globalSectors.filter(s => s.sector.id !== id))
  }

  const TagList = ({ onClick }) => {
    const info = id => {
      if (sub_sectors.length === 0) return 'Yükleniyor...'
      else {
        if (isUser) {
          const { code, name } = id
          return `${code}-${name}`
        }
      }
      const sector = sub_sectors.find(s => s.id === id)
      return `${sector.code}-${sector.name}`
    }
    return (
      <>
        {globalSectors.map((sector, index) => (
          <div
            style={{ display: 'flex', flex: 1, flexFlow: 'column' }}
            key={index}
          >
            <Alert
              onClick={onClick}
              type="success"
              style={{ margin: 5, width: '100%', flex: 1 }}
              key={index}
              color="gold"
              closable={!isUser}
              onClose={() => closeHandler(sector.sector.id)}
              message={`${sector.sector.code}-${sector.sector.title}${!hide ? `-${sector.sector_experience} Yıl` : ''}`}
            />
            {sector.sub_sector.map((s, key) => (
              <Alert
                style={{ margin: 5 }}
                key={key}
                type="info"
                message={info(s)}
              ></Alert>
            ))}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <div
        onClick={() => {
          if (!isUser) setSectorVisible(true)
        }}
        style={{
          border: !isUser && '1px solid #d9d9d9',
          color: 'rgba(0, 0, 0, 0.30)',
          padding: !isUser && '6px 11px',
          backgroundColor: '#fff',
          cursor: 'pointer',
          width: '100%'
        }}
      >
        <div style={{ flex: 1 }}>{globalSectors.length > 0 && <TagList onClick={e => e.stopPropagation()} />}</div>
        {globalSectors.length === 0 &&
          (!business
            ? 'Çalıştığınız Sektör ve Altsektörler'
            : 'İşletme Sektör ve Altsektörleri(Maksimum 3 adet seçebilirsiniz.)')}
      </div>
      <Modal
        width={1920}
        visible={sectorVisible && !isUser}
        destroyOnClose
        footer={null}
        centered
        closable={false}
        title="Sektör Ekleyin"
      >
        <Form
          ref={formV2}
          onFinish={onFinishSecond}
        >
          <Col span={24}>
            <Form.Item
              name="sectors"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Sektörleri seçiniz.'
                }
              ]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Sektör seçin"
                value={_sectors}
                onChange={e => {
                  set_Sectors(e)
                }}
              >
                {sectors.map(sector => (
                  <Option
                    key={sector.id}
                    value={sector.id}
                  >
                    {sector.code}-{sector.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {_sectors && (
            <Col span={24}>
              <Form.Item
                rules={[
                  { required: true, message: 'Lütfen alt sektör seçiniz' },
                  () => ({
                    validator(_, value) {
                      return value.length > 0
                        ? Promise.resolve()
                        : Promise.reject(new Error('En az 1 adet alt sektör seçmelisiniz.!'))
                    }
                  })
                ]}
                name="subsectors"
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Alt sektör seçin"
                  value={selectedSubSectors}
                  onChange={e => setSelectedSubSectors(e)}
                >
                  {sectors
                    .find(s => s.id === _sectors)
                    .children.map(sector => (
                      <Option
                        key={sector.id}
                        value={sector.id}
                      >
                        {sector.code}-{sector.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {selectedSubSectors.length > 0 && !hide && (
            <Col span={24}>
              <Form.Item
                name="sector_experience"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen Çalışma Sürenizi Seçiniz'
                  }
                ]}
              >
                <InputNumber
                  type="number"
                  style={{ width: '100%' }}
                  placeholder="Sektör deneyim yılınız"
                  value={sector_experience}
                  onChange={setSectorExperience}
                  min={1}
                  max={50}
                />
              </Form.Item>
            </Col>
          )}
          {error && (
            <Col
              style={{ marginBottom: 10 }}
              span={24}
            >
              <Alert
                message={errorMsg}
                type="error"
              />
            </Col>
          )}
          <Col span={24}>
            <Button
              htmlType="submit"
              type="primary"
            >
              EKLE
            </Button>
            <Button
              style={{ float: 'right' }}
              type="danger"
              onClick={() => setSectorVisible(false)}
            >
              KAPAT
            </Button>
          </Col>
        </Form>
      </Modal>
    </>
  )
}

export default SubSector
