const LOGOUT_USER = "LOGOUT_USER";
const SET_DUMB_DATAS = "SET_DUMB_DATAS";
const SET_LOADING = "SET_LOADING";
const SET_PAGES = "SET_PAGES";
const SET_EVALUATIONS = "SET_EVALUATIONS"
const SET_REMOVE_EVALUATIONS = "SET_REMOVE_EVALUATIONS"
export const logout_user = () => ({
  type: LOGOUT_USER,
});
export const set_evaluations = (payload) => ({
  type: SET_EVALUATIONS,
  payload
})
export const remove_evaluations = (payload) => ({
  type: SET_REMOVE_EVALUATIONS,
  payload
})
export const set_dumb_datas = (payload) => ({
  type: SET_DUMB_DATAS,
  payload: { ...payload },
});
export const set_loading = (payload) => ({
  type: SET_LOADING,
  payload,
});
export const set_pages = (payload) => ({
  type: SET_PAGES,
  payload,
});
export const initialState = {
  loading: false,
  news: [],
  blogs: [],
  comments: [],
  mentors: [],
  per: 20,
  page: 1,
  pages: [],
  evaluations: []
};
const dumb = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_DUMB_DATAS":
      return {
        ...state,
        news: action.payload.news,
        comments: action.payload.comments,
        blogs: action.payload.blogs,
        mentors: action.payload.mentors,
      };
    case "SET_REMOVE_EVALUATIONS":
      return {
        ...state,
        evaluations: state.evaluations.filter(e => e.id !== action.payload)
      };
    case "SET_EVALUATIONS":
      return {
        ...state,
        evaluations: action.payload
      };
    case "SET_PAGES":
      return {
        ...state,
        pages: action.payload,
      };
    default:
      return state;
  }
};
export default dumb;
