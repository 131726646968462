import OuterLayout from "../../components/OuterLayout";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { avatarFullPath, imageErrorHandler } from "../../utils/helpers";
import { DocumentCard } from "../../components/DocumentCard";
import { LandingDocumentButton } from "../../components/LandingDocument";
import axios from "../../axios";
import { LandingComment } from "../../components/Landing";
import { Pagination, Col, Row, Empty, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { CaretRightFilled } from "@ant-design/icons";
import { CommentContent } from "../../components/CommentContent";
const settings = {
  xs: 24,
  sm: 24,
  md: 8,
  lg: 8,
  xl: 6,
};

const Dumb = () => {
  const history = useHistory();
  const { dumb } = useParams();
  const [params, setParams] = useState({ page: 1, per: 10 });
  const [total, setTotal] = useState(1);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentShow, setCommentShow] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);

  const [type, setType] = useState(
    dumb === "haberler" ? "news" : dumb === "yorumlar" ? "feedbacks" : "blogs"
  );
  const [documentType, setDocumentType] = useState(
    dumb === "haberler" ? "news" : dumb === "yorumlar" ? "feedbacks" : "blog"
  );

  const commentHandler = (comment) => {
    setSelectedComment(comment);
    setCommentShow(true);
  };

  useEffect(() => {
    const getItems = async () => {
      try {
        const _type =
          dumb === "haberler"
            ? "news"
            : dumb === "yorumlar"
            ? "feedbacks"
            : "blogs";
        setLoading(true);
        setDocumentType(
          dumb === "haberler"
            ? "news"
            : dumb === "yorumlar"
            ? "feedbacks"
            : "blog"
        );
        setType(_type);
        const {
          data: {
            data: {
              items,
              pagination: { total },
            },
          },
        } = await axios.get(_type, { params });
        setItems(items);
        setTotal(total);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getItems();
    // eslint-disable-next-line
  }, [params.page, dumb]);

  const NewsAndBlogs = () => (
    <Row gutter={[12, 12]}>
      {items &&
        items.length > 0 &&
        items.map((item) => (
          <Col {...settings} key={item.id}>
            <DocumentCard>
              <img
                onError={(e) => imageErrorHandler(e)}
                src={avatarFullPath(item.pictures[0])}
                alt="Haber"
              />
              <div className="inner-document-card">
                <div className="inner-document-title">{item.title}</div>
                <div className="inner-document-content">{item.summary}</div>
                <div className="inner-document-action">
                  <div> {item.date} </div>{" "}
                  <LandingDocumentButton
                    onClick={() =>
                      history.push(`../../${dumb}/${item.id}/${documentType}`)
                    }
                    outline={false}
                  >
                    Devamını Oku{" "}
                    <CaretRightFilled style={{ color: "#fff", fontSize: 20 }} />
                  </LandingDocumentButton>
                </div>
              </div>
            </DocumentCard>
          </Col>
        ))}
    </Row>
  );

  const Comments = () => (
    <Row gutter={[12, 12]}>
      {items.map((comment, index) => (
        <Col {...settings} key={comment.id}>
          <LandingComment onClick={() => commentHandler(comment)} key={index}>
            <div className="comment-header">
              <div> {comment.name} </div>
            </div>
            <div className="comment-content">
              <img
                onError={(e) => imageErrorHandler(e, "user")}
                src={avatarFullPath(comment.picture)}
                alt="Comment"
              />
              <div>{comment.description}</div>
            </div>
          </LandingComment>
        </Col>
      ))}
    </Row>
  );
  return (
    <OuterLayout>
      {items.length > 0 && !loading ? (
        <div style={{ overflow: "hidden", padding: 24 }}>
          <h1
            style={{
              color: "#00ddbf",
              fontSize: 24,
              textTransform: "uppercase",
            }}
          >
            {" "}
            {dumb === "bloglar" ? "Blog Yazıları" : dumb}{" "}
          </h1>
          {type !== "feedbacks" ? <NewsAndBlogs /> : <Comments />}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 15,
            }}
          >
            <Pagination
              defaultCurrent={params.page}
              total={total}
              onChange={(page) => setParams({ ...params, page })}
            />
          </div>
        </div>
      ) : (
        <div style={{ padding: 24 }}>
          <Empty />
        </div>
      )}
      <Modal
        centered
        closable
        width={400}
        onCancel={() => setCommentShow(false)}
        visible={commentShow}
        footer={null}
      >
        <CommentContent {...selectedComment} />
      </Modal>
    </OuterLayout>
  );
};
export default Dumb;
