import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "../../axios"
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Col } from 'antd'
const Detail = () => {
    const { slug, type } = useParams();
    const [item, setItem] = useState("")
    useEffect(() => {
        async function getItemWithSlug() {
            try {
                const { data } = await axios.get(`/${type}/${slug}`)
                setItem(data.data)
                const { title } = data.data;
                document.title = `Kaviyer | ${title}`;
                window.scrollTo({
                    behavior: "smooth",
                    top: 0
                })
            } catch (error) {
                console.log(error)
            }
        }
        getItemWithSlug()
        return () => {
            document.title = "Kaviyer"
        }
        // eslint-disable-next-line
    }, [slug])

    const NewsAndBlogs = () => <>
        <h1 style={{ padding: "0px 10px" }}>
            {item.title}
        </h1>
        <div dangerouslySetInnerHTML={{__html: `${item.content}`}} style={{ padding: "10px 10px" }} />
        
    </>
    return (
        <>
            <Header />
            <div style={{ marginTop: '60px', padding: '24px', overflow: 'hidden' }}>
                <Col>
                    <NewsAndBlogs />
                </Col>
            </div>
            <Footer />
        </>
    )
}

export default Detail;
