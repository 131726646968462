import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Empty, Select, notification, Input } from "antd";
import { LandingDocumentButton } from "./LandingDocument";
import { MentorSpacer } from "./Mentor";
import Selectable from "./Selectable";
import axios from "../axios";
const MentorAvailability = ({ availibility, id }) => {
  const [selected, setSelected] = useState([]);
  const [category, setCategory] = useState("LESSON");
  const [message, setMessage] = useState("");
  const [place, setPlace] = useState("");
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [localAvailibility, setLocalAvailibility] = useState(availibility);
  const reservatation = async () => {
    try {
      setLoading(true);
      const body = {
        mentor: id,
        mentor_availability: selected.map((i) => i.id),
        type: category,
        appointment_message: message,
        event_place: place,
      };
      await axios.put("/set-appointment", body);
      setSelected([]);
      setLoading(false);
      setLocalAvailibility(availibility);
      setLocalAvailibility(
        localAvailibility.map((ava) =>
          selected.map((i) => i.id).includes(ava.id)
            ? { ...ava, is_reserved: true }
            : ava
        )
      );
      setMessage("");
      setPlace("")
      notification.success({
        message: "Talep",
        description: "Görüşme talebiniz başarıyla mentora iletilmiştir.",
      });
    } catch (error) {
      notification.error({
        message: "Talep",
        description: error.response.data.messages[0] || "Bir hata oluştu.",
      });
    } finally {
      setReset(true);
      setLoading(false);
      setTimeout(() => setReset(false), 1000);
    }
  };
  useEffect(() => {
    setLocalAvailibility(availibility);
  }, [availibility]);
  return (
    <>
      {availibility.length === 0 ? (
        <Empty />
      ) : (
        <Selectable
          reset={reset}
          onSelect={setSelected}
          selected={selected}
          items={localAvailibility}
        />
      )}
      <>
        <MentorSpacer />
        <div>
          <div>
            <Select
              placeholder="Görüşme Tipi"
              value={category}
              size="small"
              style={{ width: "100%", marginBottom: 5 }}
              onChange={(e) => setCategory(e)}
            >
              <Select.Option value="MEET"> Tanışma </Select.Option>)
              <Select.Option value="LESSON"> Görüşme </Select.Option>)
            </Select>
            <Input
              size="small"
              style={{ marginBottom: 5 }}
              value={message}
              placeholder="Görüşme notu giriniz..."
              onChange={(e) => setMessage(e.target.value)}
            />
            <Input
              size="small"
              style={{ marginBottom: 5 }}
              value={place}
              placeholder="Görüşme yeri giriniz..."
              onChange={(e) => setPlace(e.target.value)}
            />
          </div>
          <LandingDocumentButton
            disabled={loading || !selected || !message || !place}
            onClick={() => reservatation()}
            outline={false}
            style={{ padding: "0px 5px", float: "right" }}
          >
            Rezerve Et
          </LandingDocumentButton>
        </div>
      </>
    </>
  );
};
MentorAvailability.propTypes = {
  availibility: PropTypes.arrayOf(
    PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string,
    })
  ),
};
export default MentorAvailability;
