import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Modal,
  Checkbox,
  Cascader,
  Upload,
  notification,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import axios from "../axios/index";
import { getBase64, beforeUpload, dummyRequest } from "../utils/upload.js";
import Logo from "../components/FormLogo";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { phoneRules, lowercase, uppercase, excludeSpecialCharacters, minimumCharacter, digitRules, } from "../utils/helpers";
import SubSector from "../components/SubSector"
const { Option } = Select;
const settings = {
  xxl: 12,
  xl: 12,
  lg: 12,
  sm: 24,
  md: 12,
  xs: 24,
};
const MentorRegister = ({user = null, disabled = false}) => {
  const history = useHistory();
  const form = useRef();
  const [loading, setloading] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [_categories, set_Categories] = useState([]);
  const [cities, setCities] = useState([]);
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [emailrequired, setEmailConfirmation] = useState("")
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  const [avatar, setAvatar] = useState("");
  const [phone, setPhone] = useState("");
  const [about, setAbout] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [categories, setCategories] = useState([]);

  const [language, setLanguage] = useState([]);
  const [linkedin, setLinkedin] = useState("");
  const [education, setEducation] = useState("");
  const [education_detail, setEducationDetail] = useState("");
  const [educationText, setEducationText] = useState("Eğitim Detay");
  const [kvkk, setKvkk] = useState(false)
  const [kvkkText, setKvkkText] = useState(null)
  const [showKvkk, setShowKvkk] = useState(false)
  const [globalSectors, setGlobalSectors] = useState([])
  
  useEffect(() => {
    getCities();
    getCategories();
    getContract()
  }, []);
  const getContract = async () => {
    try {
      const { data: { data: { content } } } = await axios.get(`/contract/kvkk`)
      setKvkkText(content)
    } catch (error) {
      
    }
  }
  
  useEffect(() => {
    // ad,soyad, email, şehir/ilçe, telefon, eğitim, eğitim durumu
    if(user?.id) {
      const { name, lastname, email, city, district, phone, education, education_detail, avatar } = user;
      form.current.setFieldsValue({
        name,
        lastname,
        phone,
        education,
        education_detail,
        email,
        email_required: email,
        location: [city.id, district.id],
        avatar
      })
      setName(name)
      setLastname(lastname)
      setEmail(email)
      setEmailConfirmation(email)
      setCity(city.id)
      setDistrict(district.id)
      setPhone(phone)
      setEducation(education)
      setEducationDetail(education_detail)
      setAvatar(avatar)
    }
  }, [user])

  const getCities = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await axios.get("cities", { params: { districts: true } });
      setCities(items);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeEducation = (e) => {
    if (e === "other") {
      setEducation(e)
      setEducationText("Eğitm Bilgileriniz")
    } else {
      setEducation(e);
      setEducationText("Okul ve Bölüm Bilgileriniz")

    }
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoadingAvatar(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoadingAvatar(false);
        setAvatar(imageUrl);
      });
    }
  };
  const getCategories = async () => {
    try {
      const {
        data: { data },
      } = await axios.get("categories");
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeLocation = (e) => {
    const [city, district] = e;
    setCity(city);
    setDistrict(district);
  };
  const onFinish = async () => {
    try {
      const user_sectors = globalSectors.map(g => ({ sector: g.sector.id, sub_sector: g.sub_sector, sector_experience: g.sector_experience }))
      setloading(true);
      const body = {
        name,
        lastname,
        email,
        password,
        password_confirmation: password_confirmation,
        avatar: avatar.split(",")[1],
        phone: phone.replace(/\s/g, ""),
        city,
        district,
        categories: _categories,
        education,
        education_detail,
        language,
        linkedin,
        address,
        about
      };
      if(!user?.id) body.user_sectors =  user_sectors
      await axios.put("mentor-application", body);
      notification.success({
        message:
          "Kayıt başvurunuz alınmıştır, e-mail doğrulaması için lütfen maillerinizi kontrol ediniz.",
        placement: "topRight",
        duration: 3,
      });
      setTimeout(() => {
        history.push("/");
      }, 4000);
    } catch (error) {
      notification.error({
        message: error.response.data.messages[0] || "Lütfen bilgilerinizi gözden geçiriniz.",
        placement: "topRight",
        duration: 3,
      });
    } finally {
      setloading(false);
    }
  };
  const uploadButton = (
    <div style={{ width: "100%" }}>
      {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Resim Yükle</div>
    </div>
  );

  return (
    <div className="full-height full-height--center pd-15">
      <div className="user-form">
        { !user ? (<Link to={"/"}>
          {" "}
          <Logo /><center><h1>Rehber Başvurusu</h1></center>{" "}
        </Link>) : <h1>Rehber Olarak Kayıt</h1>}
        <Form ref={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col {...settings}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Lütfen adınızı giriniz!",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size={"large"}
                  placeholder="Ad"
                />
              </Form.Item>
            </Col>
            <Col {...settings}>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Lütfen soyadınızı giriniz!",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  size={"large"}
                  placeholder="Soyad"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Lütfen emailinizi kontrol ediniz!",
                  },
                  {
                    required: true,
                    message: "Lütfen emailinizi giriniz!",
                  },
                ]}
              >
                <Input
                 onCopy={(e)=>{
                  e.preventDefault()
                return false }}
                onPaste={(e)=>{
                  e.preventDefault()
                return false }}
                  disabled={disabled}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  size={"large"}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="email_required"
                dependencies={['email']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Lütfen emaili tekrar giriniz!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('email') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Email Eşleşmiyor!'));
                    },
                  }),
                ]}
              >
                <Input
                onCopy={(e)=>{
                  e.preventDefault()
                return false }}
                onPaste={(e)=>{
                  e.preventDefault()
                return false }}
                  disabled={disabled}
                  value={emailrequired}
                  onChange={(e) => setEmailConfirmation(e.target.value)}
                  size={"large"}
                  placeholder="Email Tekrar"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: !disabled,
                    message: "Lütfen şifrenizi giriniz!",
                  },
                  minimumCharacter,
                  digitRules,
                  uppercase,
                  lowercase,
                  excludeSpecialCharacters,
                ]}
              >
                <Input.Password
                onCopy={(e)=>{
                  e.preventDefault()
                return false }}
                onPaste={(e)=>{
                  e.preventDefault()
                return false }}
                  disabled={disabled}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size={"large"}
                  placeholder="Şifre"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                 name="password_required"
                 dependencies={['password']}
                 hasFeedback
                 rules={[
                   {
                     required: !disabled,
                     message: "Lütfen şifrenizi tekrar giriniz!",
                   },
                   minimumCharacter,
                   digitRules,
                   uppercase,
                   lowercase,
                   excludeSpecialCharacters,
                   ({ getFieldValue }) => ({
                     validator(_, value) {
                       if (!value || getFieldValue('password') === value) {
                         return Promise.resolve();
                       }
                       return Promise.reject(new Error('Şifreler Eşleşmiyor!'));
                     },
                   }),
                 ]}
              >
                <Input.Password
                onCopy={(e)=>{
                  e.preventDefault()
                return false }}
                onPaste={(e)=>{
                  e.preventDefault()
                return false }}
                  disabled={disabled}
                  value={password}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  size={"large"}
                  placeholder="Şifre tekrar"
                />
              </Form.Item>
            </Col>
        
            <Col {...settings}>
              <Form.Item
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Lütfen şehir/ilçe seçiniz.",
                  },
                ]}
              >
                <Cascader
                  disabled={disabled}
                  fieldNames={{
                    label: "name",
                    value: "id",
                    children: "districts",
                  }}
                  showSearch={{ filter(inputValue, path) {
                    return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                  }}}
                  placeholder="Şehir/İlçe Seçin"
                  onChange={onChangeLocation}
                  size="large"
                  options={cities}
                />
              </Form.Item>
            </Col>
            <Col {...settings}>
              <Form.Item
                name="phone"
                rules={phoneRules}
              >
                <Input
                  disabled={disabled}
                  maxLength={11}
                  placeholder="Tel No 05333333333"
                  size="large"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Item>
            </Col>
            {!user?.id && 
              <Col span={24}>
                <Form.Item
                  name="about"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen bize kendinizden bahsedin.",
                    },
                  ]}
                >
                  <Input.TextArea
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    size="large"
                    placeholder="Hakkınızda"
                  ></Input.TextArea>
                </Form.Item>
              </Col>
            }
            <Col span={24}>
              <Form.Item
                name="linkedin"
                rules={[
                  {
                    required: false,
                    message: "Varsa linkedin url adresi.",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setLinkedin(e.target.value)}
                  value={linkedin}
                  placeholder="Linkedin Url"
                />
              </Form.Item>
            </Col>
            {!user?.id && 
              <Col span={24}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Lütfen adres giriniz",
                    },
                  ]}
                >
                  <Input.TextArea
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    size="large"
                    placeholder="Adres"
                  ></Input.TextArea>
                </Form.Item>
              </Col>
            }
            <Col span={24}>
              <Form.Item
                name="education"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Eğitim Durumu Seçiniz!",
                  },
                ]}
              >
                <Select
                  disabled={disabled}
                  onChange={(e) => onChangeEducation(e)}
                  placeholder="Eğitim Durumu"
                >
                  <Option value="high_school">Lise</Option>
                  <Option value="college">Yüksekokul</Option>
                  <Option value="bachelor_degree">Lisans</Option>
                  <Option value="master_degree">Yüksek Lisans</Option>
                  <Option value="phd">Doktora</Option>
                  <Option value="other">Diğer</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="education_detail"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Lütfen Eğitim Bilgilerini giriniz!",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  onChange={(e) => setEducationDetail(e.target.value)}
                  value={education_detail}
                  placeholder= {educationText}
                />
              </Form.Item>
            </Col>
            {
              !user?.id && <Col span={24}>
              <Form.Item name="globalsector" rules={[{
                validator: (_, _a) =>
                  globalSectors.length > 0 ? Promise.resolve() : Promise.reject(new Error('Lütfen sektör seçiniz')),
              }]}>
                <SubSector onChange={(e) => setGlobalSectors(e)} />
              </Form.Item>
            </Col>
            }
            <Col span={24}>
              <Form.Item
                name="categories"
                rules={[
                  {
                    required: true,
                    message: "Lütfen uzmanlık alanları seçiniz.",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Uzmanlık Alanlarını Seçiniz"
                  value={_categories}
                  onChange={(e) => set_Categories(e)}
                >
                  {categories.map((cat) => (
                    <Option key={cat.id} value={cat.id}>
                      {" "}
                      {cat.title}{" "}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            
            <Col span={24}>
              <Form.Item
                name="language"
                rules={[
                  {
                    required: true,
                    message: "Lütfen dil seçiniz.",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="İletişim Dillerini Seçiniz"
                  value={language}
                  onChange={(e) => setLanguage(e)}
                >
                  <Option key="tr" value="tr">
                    {" "}
                    Türkçe{" "}
                  </Option>
                  <Option key="en" value="en">
                    {" "}
                    İngilizce{" "}
                  </Option>
                  <Option key="de" value="de">
                    Almanca
                  </Option>
                  <Option key="fr" value="fr">
                    Fransızca
                  </Option>
                  <Option key="ar" value="ar">
                    Arapça
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <div style={{ display:'flex', alignItems:'center', justifyContent: 'center', flex: 1 }}>
              <Form.Item
                name="avatar"
                rules={[
                  {
                    required: true,
                    message: "Lütfen resminizi yükleyin.",
                  },
                ]}
              >
                <Upload
                  valuePropName={avatar}
                  value={avatar}
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={dummyRequest}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  maxCount={1}
                >
                  {avatar ? (
                    <img
                      src={avatar}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </div>
            <Col span={24}>
              <Form.Item name="kvkk" valuePropName="checked" rules={[{
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('KVKK metnini onaylamak zorundasınız.')),
              }]} >
                <Checkbox checked={kvkk} onChange={(e) => setKvkk(e.target.checked)}>
                  Kvkk metnini okudum onaylıyorum. <span onClick={() => setShowKvkk(true)} style={{fontWeight: 'bold', cursor: 'pointer'}}>Okumak için tıklayınız</span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  className="user-form-button"
                >
                  KAYIT OL
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Modal visible={showKvkk} closable footer={null} centered bodyStyle={{ overflowY: 'scroll' }} onCancel={() => setShowKvkk(false)} title="KVKK Onay Metni">
          <div dangerouslySetInnerHTML={{ __html: `${kvkkText}` }}></div>
      </Modal>
    </div>
  );
};
export default MentorRegister;
