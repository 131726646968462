/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import Logo from '../assets/images/header-logo.svg'
import SlimLogo from '../assets/images/favicon.png'
import { Button, Menu, Dropdown, Modal, Switch, Rate, Form, Input, notification, Divider, Checkbox } from 'antd';
import { NoData } from "../components/NoData"
import styled from "styled-components";
import user from "../assets/images/user.jpg"
import Cookies from "js-cookie"
import { fullname, dateRangeWithFormatter } from "../utils/helpers"
import axios from "../axios"
import { useSelector, useDispatch } from "react-redux"
import { SettingOutlined, VideoCameraOutlined, HomeOutlined, InfoCircleOutlined, CalendarOutlined, LogoutOutlined, UserOutlined, NotificationOutlined, UsergroupAddOutlined, MessageOutlined, HomeTwoTone, HomeFilled } from "@ant-design/icons"
const CustomNav = styled(NavLink)`
color:#5887FF !important;
`
const { confirm } = Modal;
const fontText = {
    fontSize: 16
}
const fontIcon = {
    fontSize: 14
}
const EvaluationsForm = ({ eva, finish }) => {
    const ref = useRef()
    const [description, setDescription] = useState("")
    const [score, setScore] = useState(null)
    const { role } = useSelector(state => state.user)
    const [isComplated, setIsComplated] = useState(false)
    const onFinish = async () => {
        try {
            const body = {
                description,
                is_complated: isComplated
            }
            if(isComplated) body.score = score
            await axios.patch(`evaluation/${eva.id}`, body, { params: { role } })
            finish(eva.id)
            setDescription("")
            setScore(null)
            setIsComplated(false)
            ref.current.setFieldsValue({
                rate: null,
                description: "",
                complated: false
            })
        } catch (error) {
            console.log(error)
            notification.error({ message: 'Bir hata oluştu', duration: 1 })
        }
    }
    return (
        <Form onFinish={onFinish} ref={ref}>
            <div style={style.evaluation}>
                <div style={style.row}>
                    <div>
                        <div style={{fontWeight:'bold'}}>{fullname(eva.with_who_name, eva.with_who_lastname)}</div>
                        <div>
                            {dateRangeWithFormatter(eva.start_date, eva.end_date)}
                        </div>
                    </div>
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        name="rate"
                        rules={[{
                            required: !!isComplated,
                            message: "Puanınızı seçiniz.",
                        }]}>
                        <Rate disabled={!isComplated} value={score} onChange={(e) => setScore(e)} />
                    </Form.Item>
                </div>
                <div><Form.Item name="complated">
                        <Checkbox checked={isComplated} onChange={(e) => setIsComplated(e.target.checked)}>Görüşme Yapıldı mı?</Checkbox>
                    </Form.Item></div>
                <div>
                    <Form.Item name="description" rules={[{ required: true, message: 'Lütfen açıklama giriniz.' }]}>
                        <Input value={description} onChange={(e) => setDescription(e.target.value)} placeholder={isComplated ? 'Değerlendirme hakkında daha çok bilgi verin..' : 'Görüşmenin neden yapılmadığıyla ilgili bilgi verin.'} />
                    </Form.Item>
                </div>
                <div>
                    <Button htmlType="submit">Kaydet</Button>
                </div>
            </div>
            <Divider />
        </Form>
    )
}
const InnerHeader = () => {
    const { me: { avatar, roles, business_in_role, business_type }, role } = useSelector(state => state.user)
    const { evaluations } = useSelector(state => state.dumb)
    const history = useHistory();
    const imageRef = useRef(null)
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const roleChecker = () => roles && roles.some(r => r === "USER") && roles.some(r => r === "MENTOR")
    const roleText = () => {
        if (roleChecker() || !role) return;
        return roles && roles.length > 0 && roles.some(r => r === "USER") ? "Rehber Kayıt" : "Danışan Kayıt"
    }
    const roleRoute = () => {
        if (roleChecker() || !role) return;
        return roles && roles.length > 0 && roles.some(r => r === "USER") ? "mentor-kayit" : "user-kayit"
    }
    const menu = (
        <Menu style={{ width: 250 }}>
            {role !== 'USER' && (
                <Menu.Item className="show-on-phone">
                    <CustomNav exact style={{ ...fontText }} activeClassName="active-navlink" to={"/app/gorusme-talepleri"}>
                        <HomeFilled style={{ ...fontIcon, marginRight: 4 }}/>
                        Görüşme Talepleri
                    </CustomNav>
                </Menu.Item>
            )}
            <Menu.Item className="show-on-phone">
                <CustomNav exact style={{ ...fontText }} activeClassName="active-navlink" to={"/app"}>
                    <HomeOutlined style={{ ...fontIcon, marginRight: 4 }}/>
                    Rehberler
                </CustomNav>
            </Menu.Item>
            <Menu.Item className="show-on-phone border-bottom">
                <CustomNav activeClassName="active-navlink" style={{ ...fontIcon }} exact to={"/app/destek"}>
                    <InfoCircleOutlined style={{ ...fontIcon, marginRight: 4 }}/>
                    Destek
                </CustomNav>
            </Menu.Item>
            <Menu.Item>
                <CustomNav exact activeClassName="active-navlink" style={{ ...fontText }} to={"/app/profile"}>
                    <SettingOutlined style={{ ...fontIcon }} /> Hesap Ayarları
                </CustomNav>
            </Menu.Item>
            <Menu.Item>
                <CustomNav exact activeClassName="active-navlink" style={{ ...fontText }} to={"/app/gorusme-kayitlarim"}>
                    <VideoCameraOutlined style={{ ...fontIcon }} /> Görüşme Kayıtlarım
                </CustomNav>
            </Menu.Item>
            <Menu.Item>
                <CustomNav exact activeClassName="active-navlink" style={{ ...fontText }} to={"/app/mesajlarim"}>
                    <MessageOutlined style={{ ...fontIcon }} /> Mesajlarım
                </CustomNav>
            </Menu.Item>
            <Menu.Item className={roleChecker() ? "border-bottom" : ""}>
                <CustomNav exact activeClassName="active-navlink" style={{ ...fontText }} to={"/app/takvim"}>
                    <CalendarOutlined style={{ ...fontIcon }} /> Takvim
                </CustomNav>
            </Menu.Item>
            <Menu.Item>
                <a onClick={() => setShow(true)} style={{ ...fontText, color: '#5887FF' }}><NotificationOutlined style={{ ...fontIcon, color: '#5887FF' }} />  Değerlendirme</a>
            </Menu.Item>
            {
              (!["individual_application"].some(s => s === business_type)) &&  business_in_role === 'MANAGER' && roles && roles.length > 0 && roles.includes('USER') && role && role === 'USER' && (
                    <Menu.Item>
                        <CustomNav exact activeClassName="active-navlink" style={{ ...fontText }} to={"/app/kullanicilar"}>
                            <UsergroupAddOutlined style={{ ...fontIcon }} /> Firma Danışanları
                        </CustomNav>
                    </Menu.Item>)
            }
            {
                !roleChecker() && (
                    <Menu.Item className="border-bottom">
                        <CustomNav exact activeClassName="active-navlink" style={{ ...fontText }} to={`/app/${roleRoute()}`}>
                            <UserOutlined style={{ ...fontIcon }} /> {roleText()}
                        </CustomNav>
                    </Menu.Item>)
            }
            <Menu.Item>
                <a onClick={() => showConfirm()} style={{ ...fontText }}>
                    <LogoutOutlined style={{ ...fontIcon }} /> Oturumu Kapat
                </a>
            </Menu.Item>
        </Menu>
    );

    const showConfirm = () => {
        confirm({
            closable: true,
            cancelButtonProps: { type: 'text', danger: true },
            okType: "text",
            title: 'Çıkış yapmak istediğinize emin misiniz?',
            onOk() {
                dispatch({ type: 'LOGOUT_USER' })
                Cookies.remove("token", { domain: process.env.REACT_APP_WILD_CARD })
                Cookies.remove("refresh_token")
                Cookies.remove("roles")
                history.push("/")
            }
        });
    }
    const roleHandler = (e) => {
        const role = e ? "USER" : "MENTOR"
        dispatch({ type: "SET_USER_ROLE", payload: role })
        Cookies.set("roles", role)
        if (role === 'USER') {
            history.push("/app")
        }
        else {
            history.push("/app/gorusme-talepleri")
        }
    }
    const imageErrorHandler = () => {
        imageRef.current.src = user;
    }

    useEffect(() => {
        async function getEvaluations() {
            try {
                const params = {}
                if (role) params.role = role
                const { data: { data: { evaluations } } } = await axios.get(`evaluations`, { params })
                dispatch({ type: 'SET_EVALUATIONS', payload: evaluations })
            } catch (error) {

            }
        }
        getEvaluations()
        // eslint-disable-next-line
    }, [role])
    const onFinish = (payload) => dispatch({ type: 'SET_REMOVE_EVALUATIONS', payload })
    return (
        <div id="header">
            <div className="menu-items">
                <Link to={"/app"}>
                    <img src={Logo} className="hide-on-phone" alt="" />
                    <img src={SlimLogo} className="show-on-phone" alt="" />
                </Link>
                {role !== "USER" && 
                <Button type={"link"} className="hide-on-phone">
                    <NavLink exact activeClassName="active-navlink" to={"/app/gorusme-talepleri"}>Görüşme Talepleri</NavLink>
                </Button>
                }
                <Button type="link" className="hide-on-phone">
                    <NavLink exact activeClassName="active-navlink" to={"/app"}>Rehberler</NavLink>
                </Button>
                <Button type={"link"} className="hide-on-phone">
                    <NavLink activeClassName="active-navlink" exact to={"/app/destek"}>Destek</NavLink>
                </Button>
            </div>
            <div className="menu-action-controller">
                {roleChecker() && <div className="menu-switch">
                    <Switch checked={role === "USER"} checkedChildren="Danışan" unCheckedChildren="Rehber" onChange={(e) => roleHandler(e)} />
                </div>}
                <div className="menu-auth align-end">
                    <Dropdown trigger={['click']} overlay={menu} placement="bottomLeft" >
                        <img className="avatar cursor-pointer" onClick={e => e.preventDefault()} ref={imageRef} onError={imageErrorHandler} src={`${avatar}?${new Date().toISOString()}`} alt="Avatar" />
                    </Dropdown>
                </div>
            </div>
            <Modal closable destroyOnClose footer={null} onCancel={() => setShow(false)} visible={show} style={{ top: 20 }} title="Görüşme Değerlendirme">
                {evaluations.map((e, index) => (
                    <EvaluationsForm eva={e} finish={onFinish} key={index} />
                )
                )}
                {evaluations.length === 0 && <NoData title="Değerlendirilmemiş görüşme bulunamadı." />}
            </Modal>
        </div>
    )
}
const style = {
    evaluation: {
        display: 'flex',
        paddingBlock: 10,
        flexFlow: 'column nowrap'
    },
    row: {
        display: 'flex',
        flex: '1 1 100%',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
    }
}
export default InnerHeader;
