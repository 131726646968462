import React from "react"
import InnerWrapper from "../components/InnerWrapper";
import Register from "../pages/Register"
import { useSelector } from "react-redux"
const InnerUserRegister = () => {
    const { me } = useSelector(state => state.user)
    return (
        <InnerWrapper>
            <Register user={me} disabled />
        </InnerWrapper>
    )
}
export default InnerUserRegister;