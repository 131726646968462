import React, { useState } from 'react'
import styled from 'styled-components'
import user from '../assets/images/user.jpg'
import { Rate, Button, Modal, Form, Col, Input, notification } from 'antd'
import axios from '../axios/'
import MentorModal from './MentorModal'
const MentorCard = styled.div`
  height: 260px;
  background: #fff;
  padding: 20px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`
export const MentorAvatar = styled.img`
  height: 75px;
  width: 75px;
  object-fit: cover;
  border-radius: ${props => (props.circle ? '50%' : '3px')};
`
export const MentorHead = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
export const MentorHeadContent = styled.div`
  display: flex;
  margin-left: 10px;
  flex-flow: column nowrap;
`
export const MentorCountry = styled.div`
  font-size: 12px;
  color: #666;
  font-weight: 300;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`
export const MentorFullname = styled.div`
  font-weight: bold;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 20px;
  color: #000;
`
export const MentorContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: ${props => (props.noMargin ? '0px' : '10px')};
  justify-content: space-between;
  height: ${props => (!props.height ? '125px' : '')};
`
export const MentorDescription = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${props => (!props.more ? '3' : '50')};
  -webkit-box-orient: vertical;
`
const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row-reverse nowrap;
`
export const MentorContainer = styled.div`
  overflow: hidden;
  padding: 15px;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
`
export const MentorSearchOuter = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  padding: 15px 0px;
  background: #fff;
`
export const MentorSearch = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 0px 13px;
  flex-flow: column nowrap;
  max-width: 1600px;
  width: 100%;
`
export const MensorSearchCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-flow: row nowrap;
  margin-bottom: 10px;
`
export const MentorModalContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
export const MentorSpacer = styled.div`
  margin: 15px 0px;
  background: rgba(0, 0, 0, 0.12);
  height: 1px;
`
export const MentorRate = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  color: #666;
  font-weight: 500;
  display: flex;
`
const Mentor = props => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [mentor, setMentor] = useState(null)
  const [messageShow, setMessageShow] = useState(false)
  const [messageId, setMessageId] = useState(null)
  const [messageTitle, setMessageTitle] = useState(null)
  const [messageContent, setMessageContent] = useState(null)
  const mentorDetail = async id => {
    try {
      setLoading(true)
      const {
        data: {
          data: { items }
        }
      } = await axios.get(`/mentor/${id}`)
      setMentor(items)
      setShow(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const mentorMessageSend = id => {
    setMessageId(id)
    setMessageShow(true)
  }
  const onFinish = async () => {
    try {
      setLoading(true)
      const body = {
        title: messageTitle,
        content: messageContent,
        message_to: messageId
      }
      await axios.put('/messages', body)
      notification.success({
        message: 'Mesajınız başarıyla gönderildi.',
        placement: 'topRight',
        duration: 2
      })

      setMessageShow(false)
    } catch (error) {
      notification.error({
        message: 'Bir şeyler ters gitti 😥',
        placement: 'topRight',
        duration: 2
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <MentorCard>
        <MentorHead>
          <MentorAvatar
            onError={e => (e.target.src = user)}
            src={props.avatar}
          />
          <MentorHeadContent>
            <MentorFullname>{`${props.name} ${props.lastname}`}</MentorFullname>
            <MentorCountry> {`${props.city?.name}/${props.district?.name}`} </MentorCountry>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Rate
                allowHalf
                disabled
                style={{ fontSize: 12 }}
                value={props.score}
              />{' '}
              <MentorRate>{props.score.toFixed(2)}</MentorRate>
            </div>
            <div>
              {' '}
              <strong>{props.score_count}</strong> değerlendirme
            </div>
          </MentorHeadContent>
        </MentorHead>
        <MentorContent>
          <MentorDescription>{props.about}</MentorDescription>
          <ButtonGroup>
            <Button
              style={{ marginLeft: 5 }}
              loading={loading}
              type="primary"
              onClick={() => mentorDetail(props.id)}
            >
              Profil/Takvim
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              loading={loading}
              type="ghost"
              onClick={() => mentorMessageSend(props.id)}
            >
              Mesaj Gönder
            </Button>
          </ButtonGroup>
        </MentorContent>
      </MentorCard>
      <Modal
        centered
        closable
        width={400}
        onCancel={() => setShow(false)}
        visible={show}
        footer={null}
      >
        <MentorModal
          closeHandle={val => setShow(val)}
          mentor={mentor}
        />
      </Modal>
      <Modal
        centered
        closable
        width={400}
        onCancel={() => setMessageShow(false)}
        visible={messageShow}
        destroyOnClose={true}
        footer={null}
      >
        <Col>
          <h1>Mesaj Gönder!</h1>
        </Col>
        <Form onFinish={onFinish}>
          <Col>
            <Form.Item
              name="title"
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'Lütfen mesaj başlığını giriniz.'
                }
              ]}
            >
              <Input
                onChange={e => setMessageTitle(e.target.value)}
                value={messageTitle}
                placeholder="Başlık"
                size={'large'}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="content"
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'Lütfen mesaj içeriğini giriniz.'
                }
              ]}
            >
              <Input.TextArea
                onChange={e => setMessageContent(e.target.value)}
                value={messageContent}
                placeholder="İçerik"
                size={'large'}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              loading={loading}
              type="primary"
            >
              Gönder
            </Button>
          </Col>
        </Form>
      </Modal>
    </>
  )
}

export default Mentor
