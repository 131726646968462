import React, { useEffect, useState } from 'react'
import { Collapse } from "antd";
import Header from "../components/Header";
import Cookies from "js-cookie";
import InnerHeader from "../components/InnerHeader";
import Footer from "../components/Footer";
import axios from "../axios"
import { faqTypes } from "../utils/helpers"
const { Panel } = Collapse;
// const { Option } = Select
// const types = [
//     {
//         name: "Tümü",
//         value: "all"
//     },
//     {
//         name: "Kullanıcı",
//         value: "user"
//     },
//     {
//         name: "Mentor",
//         value: "mentor"
//     },
//     {
//         name: "Yardım",
//         value: "help"
//     }
// ]
const Faq = () => {
    const [faqs, setFaqs] = useState([])
    useEffect(() => {
        async function getFaqs() {
            try {
                const params = {
                    page: 1,
                    per: 2_000_000
                }
                const { data: { data: { items } } } = await axios.get('/faqs', { params })
                let _items = []
                items.map(item => {
                    const { type } = item
                    !_items.some((_item => _item.type === type)) && _items.push({type, data: []})
                    return ""
                })
                items.map(({type, ...rest}) => _items.map(item => type === item.type ? item.data = [...item.data, {type, ...rest}] : null))
                setFaqs(_items)
            } catch (error) {
                console.log(error)
            }
        }
        getFaqs()
    }, [])

    // const filteredFaqs = type === "all" ? faqs : faqs.filter(f => f.type === type)

    const CrossHeader = () => {
        const token = Cookies.get("token");
        return <>{token ? <InnerHeader /> : <Header />}</>;
    };
    return (
        <div>
          <CrossHeader />
          <div style={{ padding: "24px", overflow: "hidden" }}>
            {/* <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', flexFlow:'nowrap'}}>
                <h1>Sıkça Sorulan Sorular</h1>
                <Select value={type} defaultValue="all" onChange={(e) => setType(e)} style={{width: 100}}>
                    {types.map(t => <Option value={t.value} key={t.value}> {t.name} </Option>)}
                </Select>
            </div> */}
            <Collapse accordion  style={{marginTop: 60}}>
                {faqs.map((faq, index) => 
                    <Panel header={faqTypes[faq.type].text} key={index}>
                        {faq.data.map((item,j) => (
                            <div key={j} style={{display: 'flex', flexFlow:'column'}}>
                                <strong>{item.title}</strong>
                                <div dangerouslySetInnerHTML={{ __html: `${item.content}` }} />
                                <div>
                                    {item.description}
                                </div>
                            </div>
                        ))}
                    </Panel>
                )}
            </Collapse>
          </div>
          <Footer />
        </div>
      );
}

export default Faq
