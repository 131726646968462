/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  notification,
  Row,
  Col,
  Upload,
  Cascader,
  Checkbox,
  Select,
  Modal,
  InputNumber,
  Divider,
} from "antd";
import {
  MailOutlined,
  LockOutlined,
  UserOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SubSector from "../components/SubSector";
import { Link, useHistory } from "react-router-dom";
import axios from "../axios";
import Logo from "../components/FormLogo";
import { getBase64, beforeUpload, dummyRequest } from "../utils/upload.js";
import {
  digitRules,
  excludeSpecialCharacters,
  lowercase,
  phoneRulesNotRequired,
  minimumCharacter,
  phoneRules,
  uppercase,
} from "../utils/helpers";
const settings = {
  xxl: 12,
  xl: 12,
  lg: 12,
  sm: 24,
  md: 12,
  xs: 24,
};
const { Option } = Select;
const Register = ({ user = null, disabled = false }) => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailrequired, setEmailConfirmation] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [avatar, setAvatar] = useState("");
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [showKvkk, setShowKvkk] = useState(false);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [disability, setDisability] = useState("");
  const [education, setEducation] = useState("");
  const [education_detail, setEducationDetail] = useState("");
  const [educationText, setEducationText] = useState("Eğitim Detay");
  const [linkedin, setLinkedin] = useState("");
  const [about, setAbout] = useState("");
  const [sectors, setSectors] = useState([]);
  const [globalSectors, setGlobalSectors] = useState([]);
  const [business_website, setBusinessWebsite] = useState("");
  const [business_group, setBusinessGroup] = useState("");
  const [business_adress, setBusinessAddress] = useState("");
  const [business_city, setBusinessCity] = useState("");
  const [business_phone, setBusinessPhone] = useState("");
  const [business_general, setBusinessGeneral] = useState("");

  const [business_type, setBusinesstype] = useState("");
  const [business_name, setBusinessname] = useState("");
  const [business_role, setBusinessrole] = useState("");
  const [business_working_time, setBusinessworkingtime] = useState("");
  // eslint-disable-next-line
  const [business_sector, setBusinesssector] = useState([]);
  const [_business_sector, set_Businesssector] = useState([]);
  const [businessstatus, setBusinessStatus] = useState(false);
  const [kvkk, setKvkk] = useState(false);
  const [kvkkText, setKvkkText] = useState(null);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    getCities();
    getContract();
    getSectors();
  }, []);

  const getContract = async () => {
    try {
      const {
        data: {
          data: { content },
        },
      } = await axios.get(`/contract/kvkk`);
      setKvkkText(content);
    } catch (error) {}
  };

  useEffect(() => {
    if (user?.id) {
      const {
        name,
        lastname,
        email,
        city,
        district,
        phone,
        education,
        education_detail,
        avatar,
        business_website,
        business_group,
        business_adress,
        business_city,
        business_phone,
        business_general,
      } = user;
      form.current.setFieldsValue({
        name,
        lastname,
        phone,
        education,
        education_detail,
        email,
        email_required: email,
        location: [city.id, district.id],
        avatar,
        business_website,
        business_group,
        business_adress,
        business_city: business_city ? [business_city.id] : "",
        business_phone,
        business_general,
      });
      setName(name);
      setLastname(lastname);
      setEmail(email);
      setEmailConfirmation(email);
      setCity(city.id);
      setDistrict(district.id);
      setPhone(phone);
      setEducation(education);
      setEducationDetail(education_detail);
      setAvatar(avatar);
      setBusinessAddress(business_adress);
      setBusinessCity(business_city ? business_city.id : "");
      setBusinessPhone(business_phone);
      setBusinessGeneral(business_general);
      setBusinessWebsite(business_website);
      setBusinessGroup(business_group);
    }
  }, [user]);

  const getCities = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await axios.get("cities", { params: { districts: true } });
      setCities(items);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeLocation = (e) => {
    const [city, district] = e;
    setCity(city);
    setDistrict(district);
  };

  const onChanceGender = (e) => {
    setGender(e);
  };
  const onChangeDisability = (e) => {
    setDisability(e);
  };

  const onChangeBusinesstype = (e) => {
    if (e === "individual_application") {
      setBusinessStatus(false);
      setBusinesstype(e);
    } else {
      setBusinessStatus(true);
      setBusinesstype(e);
    }
  };

  const onChangeBusinessWordtime = (e) => {
    setBusinessworkingtime(e);
  };

  const onChangeEducation = (e) => {
    if (e === "other") {
      setEducation(e);
      setEducationText("Eğitm Bilgileriniz");
    } else {
      setEducation(e);
      setEducationText("Okul ve Bölüm Bilgileriniz");
    }
  };

  const getSectors = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await axios.get("sectors");
      const {
        data: { data },
      } = await axios.get("/subsectors");
      const localeSector = items.map((s) => ({ ...s, children: [] }));
      setSectors(
        localeSector.map((sector) => ({
          ...sector,
          children: data.items.filter((s) => s.sector.id === sector.id),
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  let history = useHistory();

  const onFinish = async () => {
    setLoading(true);
    try {
      const user_sectors = globalSectors.map((g) => ({
        sector: g.sector.id,
        sub_sector: g.sub_sector,
        sector_experience: g.sector_experience,
      }));
      const business_sectors = business_sector.map((s) => ({
        sector: s.sector.id,
        sub_sector: s.sub_sector,
      }));
      const body = {
        name,
        lastname,
        email,
        password,
        password_confirmation,
        phone,
        city,
        district,
        avatar: avatar.split(",")[1],
        gender,
        disability,
        education,
        education_detail,
        business_type,
        business_name,
        business_role,
        business_working_time: business_working_time
          ? `${business_working_time}`
          : "",
        business_sectors,
        business_website,
        business_group,
        business_adress,
        business_city,
        business_phone,
        business_general,
      };
      if (!user?.id) {
        body.user_sectors = user_sectors;
        body.linkedin = linkedin;
        body.address = address;
        body.about = about;
      }
      await axios.post("/auth/register", body);
      notification.success({
        message:
          "Kayıt başvurunuz alınmıştır, e-mail doğrulaması için lütfen maillerinizi kontrol ediniz.",
        placement: "topRight",
        duration: 2,
      });
      setTimeout(() => {
        history.push("/giris");
      }, 2000);
    } catch (err) {
      notification.error({
        message: err.response.data.messages[0],
        placement: "topRight",
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoadingAvatar(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoadingAvatar(false);
        setAvatar(imageUrl);
      });
    }
  };
  const uploadButton = (
    <div style={{ width: "100%" }}>
      {loadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Resim Yükle</div>
    </div>
  );
  return (
    <div className="full-height full-height--center pd-15">
      <div className="user-form">
        {!user ? (
          <Link to={"/"}>
            <Logo />{" "}
            <center>
              <h1>Danışan Başvurusu</h1>
            </center>
          </Link>
        ) : (
          <h1>Danışan Olarak Kayıt</h1>
        )}
        <Form ref={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col {...settings}>
              <Form.Item
                name="name"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Lütfen adınızı giriniz!",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  size={"large"}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Ad"
                />
              </Form.Item>
            </Col>
            <Col {...settings}>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Lütfen soyadınızı giriniz!",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setLastname(e.target.value)}
                  disabled={disabled}
                  value={lastname}
                  size={"large"}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Soyad"
                />
              </Form.Item>
            </Col>
            <Col {...settings}>
              <Form.Item name="phone" rules={phoneRules}>
                <Input
                  disabled={disabled}
                  maxLength={11}
                  placeholder="Tel No 05333333333"
                  size="large"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col {...settings}>
              <Form.Item
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Lütfen şehir/ilçe seçiniz.",
                  },
                ]}
              >
                <Cascader
                  disabled={disabled}
                  fieldNames={{
                    label: "name",
                    value: "id",
                    children: "districts",
                  }}
                  showSearch={{
                    filter(inputValue, path) {
                      return path.some(
                        (option) =>
                          option.name
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                      );
                    },
                  }}
                  placeholder="Şehir/İlçe Seçin"
                  onChange={onChangeLocation}
                  size="large"
                  options={cities}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Lütfen emailinizi kontrol ediniz!",
                  },
                  {
                    required: true,
                    message: "Lütfen emailinizi giriniz!",
                  },
                ]}
              >
                <Input
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={disabled}
                  value={email}
                  size={"large"}
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="email_required"
                dependencies={["email"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Lütfen emaili tekrar giriniz!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("email") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Email Eşleşmiyor!"));
                    },
                  }),
                ]}
              >
                <Input
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={disabled}
                  value={emailrequired}
                  onChange={(e) => setEmailConfirmation(e.target.value)}
                  size={"large"}
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email Tekrar"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password"
                rules={[
                  minimumCharacter,
                  digitRules,
                  uppercase,
                  lowercase,
                  excludeSpecialCharacters,
                  {
                    required: disabled ? false : true,
                    message: "Lütfen bu alanı doldurunuz.",
                  },
                ]}
              >
                <Input.Password
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={disabled}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size={"large"}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Şifre"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="password_required"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: !disabled,
                    message: "Lütfen şifrenizi tekrar giriniz!",
                  },
                  minimumCharacter,
                  digitRules,
                  uppercase,
                  lowercase,
                  excludeSpecialCharacters,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Şifreler Eşleşmiyor!"));
                    },
                  }),
                ]}
              >
                <Input.Password
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  disabled={disabled}
                  value={password}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  size={"large"}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Şifre Tekrar"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="education"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Eğitim Durumu Seçiniz!",
                  },
                ]}
              >
                <Select
                  disabled={disabled}
                  onChange={(e) => onChangeEducation(e)}
                  placeholder="Eğitim Durumu"
                >
                  <Option value="high_school">Lise</Option>
                  <Option value="college">Yüksekokul</Option>
                  <Option value="bachelor_degree">Lisans</Option>
                  <Option value="master_degree">Yüksek Lisans</Option>
                  <Option value="phd">Doktora</Option>
                  <Option value="other">Diğer</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="education_detail"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Lütfen Eğitim Bilgilerini giriniz!",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  onChange={(e) => setEducationDetail(e.target.value)}
                  value={education_detail}
                  placeholder={educationText}
                />
              </Form.Item>
            </Col>
            <Col {...settings}>
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Cinsiyet Seçiniz!",
                  },
                ]}
              >
                <Select
                  onChange={(e) => onChanceGender(e)}
                  placeholder="Cinsiyet"
                >
                  <Option value="female">Kadın</Option>
                  <Option value="male">Erkek</Option>
                  <Option value="other">Belirtmek İstemiyorum</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...settings}>
              <Form.Item
                name="disability"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Engel Durumu Seçiniz!",
                  },
                ]}
              >
                <Select
                  onChange={(e) => onChangeDisability(e)}
                  placeholder="Bir Engel Durumunuz Var mı? "
                >
                  <Option value="no">Hayır</Option>
                  <Option value="yes">Evet</Option>
                  <Option value="other">Belirtmek İstemiyorum</Option>
                </Select>
              </Form.Item>
            </Col>
            {!user?.id && (
              <Col span={24}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: false,
                      message: "Lütfen adres giriniz",
                    },
                  ]}
                >
                  <Input.TextArea
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    size="large"
                    placeholder="Adres"
                  ></Input.TextArea>
                </Form.Item>
              </Col>
            )}
            {!user?.id && (
              <Col span={24}>
                <Form.Item
                  name="about"
                  rules={[
                    {
                      required: true,
                      message: "Boş Geçilemez",
                    },
                  ]}
                >
                  <Input.TextArea
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    placeholder="Kısaca iş hayatınızı, tecrübelerinizi ve aldığınız eğitiml eri anlatır mısınız?"
                  ></Input.TextArea>
                </Form.Item>
              </Col>
            )}
            {!user?.id && (
              <Col span={24}>
                <Form.Item
                  name="globalsector"
                  rules={[
                    {
                      validator: (_, _a) =>
                        globalSectors.length > 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Lütfen sektör seçiniz")),
                    },
                  ]}
                >
                  <SubSector onChange={(e) => setGlobalSectors(e)} />
                </Form.Item>
              </Col>
            )}
            {!user?.id && (
              <Col span={24}>
                <Form.Item>
                  <Input
                    onChange={(e) => setLinkedin(e.target.value)}
                    value={linkedin}
                    placeholder="LinkedIn Profil Url"
                  />
                </Form.Item>
              </Col>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <Form.Item
                name="avatar"
                rules={[
                  {
                    required: false,
                    message: "Lütfen resminizi yükleyin.",
                  },
                ]}
              >
                <Upload
                  style={{ width: "100%" }}
                  valuePropName={avatar}
                  listType="picture-card"
                  value={avatar}
                  className="avatar-uploader"
                  showUploadList={false}
                  customRequest={dummyRequest}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  maxCount={1}
                >
                  {avatar ? (
                    <img
                      src={avatar}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </div>
            <Divider>İşletme Bilgileri</Divider>
            <Col span={24}>
              <Form.Item
                name="business_type"
                rules={[
                  {
                    required: true,
                    message: "Lütfen İşletme Türü Seçiniz!",
                  },
                ]}
              >
                <Select
                  onChange={(e) => onChangeBusinesstype(e)}
                  placeholder="İşletme Türü "
                >
                  <Option value="individual_company">Şahıs Şirketi</Option>
                  <Option value="limited_company">Limited Şirket</Option>
                  <Option value="incorporated_company">Anonim Şirket</Option>
                  <Option value="cooperative">Kooperatif</Option>
                  <Option value="company_at_idea_stage">
                    Şirket Fikir Aşamasında
                  </Option>
                  <Option value="individual_application">
                    Şirket Bulunmuyor/Bireysel Başvuru
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="business_name"
                rules={[
                  {
                    type: "string",
                    required: businessstatus,
                    message: "Lütfen İşletme Adını giriniz!",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setBusinessname(e.target.value)}
                  value={business_name}
                  placeholder="İşletme Adı"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="business_role"
                rules={[
                  {
                    type: "string",
                    required: businessstatus,
                    message: "Lütfen İşletmede Görev Tanımını Giriniz!",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setBusinessrole(e.target.value)}
                  value={business_role}
                  placeholder="İşletmedeki Göreviniz"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="business_sector"
                rules={[
                  {
                    required: businessstatus,
                    message: "Lütfen işletme sektör ve alt sektörünü giriniz.",
                  },
                  {
                    validator: (_, _a) =>
                      business_sector.length > 0 || !businessstatus
                        ? Promise.resolve()
                        : Promise.reject(new Error("Lütfen sektör seçiniz")),
                  },
                ]}
              >
                <SubSector
                  hide
                  business
                  onChange={(e) => setBusinesssector(e)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="business_working_time"
                rules={[
                  {
                    required: businessstatus,
                    message: "İşletme kuruluş yılı giriniz.",
                  },
                  {
                    validator: (_, value) => {
                      return (value <= currentYear && value >= 1900) ||
                        !businessstatus
                        ? Promise.resolve()
                        : Promise.reject(
                            `Kuruluş yılınız 1900-${currentYear} yılları arasında olmak zorundadır.`
                          );
                    },
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  style={{ width: "100%" }}
                  placeholder="İşletme kuruluş yılı"
                  value={business_working_time}
                  onChange={(e) => setBusinessworkingtime(e)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="business_website">
                <Input
                  onChange={(e) => setBusinessWebsite(e.target.value)}
                  value={business_website}
                  placeholder="Şirket websitesi URL"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="business_group">
                <Input
                  onChange={(e) => setBusinessGroup(e.target.value)}
                  value={business_group}
                  placeholder="Şirket Grup"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="business_adress">
                <Input
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  value={business_adress}
                  placeholder="Şirket Adres"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="business_city">
                <Cascader
                  value={business_city}
                  fieldNames={{
                    label: "name",
                    value: "id",
                  }}
                  showSearch={{
                    filter(inputValue, path) {
                      return path.some(
                        (option) =>
                          option.name
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) > -1
                      );
                    },
                  }}
                  placeholder="Şirket Şehir Seçin"
                  onChange={(e) => setBusinessCity(e[0])}
                  options={cities}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="business_phone" rules={phoneRulesNotRequired}>
                <Input
                  maxLength={11}
                  placeholder="Şirket Tel No 05333333333"
                  value={business_phone}
                  onChange={(e) => setBusinessPhone(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="business_general">
                <Input
                  onChange={(e) => setBusinessGeneral(e.target.value)}
                  value={business_general}
                  placeholder="Şirket Genel Bilgiler"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="kvkk"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("KVKK metnini onaylamak zorundasınız.")
                          ),
                  },
                ]}
              >
                <Checkbox
                  checked={kvkk}
                  onChange={(e) => setKvkk(e.target.checked)}
                >
                  Kvkk metnini okudum onaylıyorum.{" "}
                  <span
                    onClick={() => setShowKvkk(true)}
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                  >
                    Okumak için tıklayınız
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  className="user-form-button"
                >
                  KAYIT OL
                </Button>
                {!user && (
                  <>
                    <div>
                      Hesabiniz var mi?{" "}
                      <Link to={"/giris"}> Giris Yapiniz!</Link>
                    </div>
                    <div>
                      Rehber mi olmak istiyorsun?{" "}
                      <Link to={"/kayit-mentor"}>Başvurabilirsiniz!</Link>
                    </div>
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Modal
        visible={showKvkk}
        closable
        footer={null}
        centered
        bodyStyle={{ overflowY: "scroll" }}
        onCancel={() => setShowKvkk(false)}
        title="KVKK Onay Metni"
      >
        <div dangerouslySetInnerHTML={{ __html: `${kvkkText}` }}></div>
      </Modal>
    </div>
  );
};

export default Register;
