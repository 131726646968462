import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import axios from "../axios/index";
import Logo from "../components/FormLogo";
import Cookies from 'js-cookie'
const Login = () => {
    let history = useHistory()
    const onFinish = async () => {
        setLoading(true)
        try {
            const body = {
                email,
                password
            }
            const { data: { token, refresh_token, roles } } = await axios.post('/auth/login', body);
            Cookies.set('token', token, { domain: process.env.REACT_APP_WILD_CARD });
            refresh_token && Cookies.set('refresh_token', refresh_token);
            const [role] = roles;
            Cookies.set('roles', role)
            setTimeout(() => {
                role === 'USER' ? history.push('/app') : history.push('/app/gorusme-talepleri');
                setLoading(false)
            }, 500);
        } catch (err) {
            console.log(err)
            const message = err.response.data.messages[0];
            notification.error({
                message,
                placement: 'topRight'
            })
            setLoading(false)
        }
    }
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    return (
        <div className="full-height full-height--center pd-15">
            <div className="user-form">
                <Link to={"/"}> <Logo /> </Link>
                <Form
                    initialValues={{
                        email: Cookies.get('email') || "",
                        password: Cookies.get('password') ||""
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Lütfen emailinizi kontrol ediniz!',
                            },
                            {
                                required: true,
                                message: 'Lütfen emailinizi giriniz!',
                            },
                        ]}
                    >
                        <Input autoComplete="off"onCopy={(e)=>{
                    e.preventDefault()
                  return false }}
                  onPaste={(e)=>{
                    e.preventDefault()
                  return false }} onChange={(e) => setEmail(e.target.value)} value={email} size={"large"}
                            prefix={<MailOutlined className="site-form-item-icon" />}
                            placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen şifrenizi giriniz!',
                            },
                        ]}
                    >
                        <Input.Password
                        onCopy={(e)=>{
                            e.preventDefault()
                          return false }}
                          onPaste={(e)=>{
                            e.preventDefault()
                          return false }}
                            autoComplete="off"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            size={"large"}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Şifre"
                        />
                    </Form.Item>
                    <Form.Item>
                        {/* <Form.Item valuePropName="checked" noStyle>
                            <Checkbox checked={remember} onChange={(e) => setRemember(e.target.checked)}>Beni
                            Hatırla</Checkbox>
                        </Form.Item> */}

                        <Link className="user-form-forgot" to={"/sifremi-unuttum"}>
                            Şifreni mi unuttun?
                    </Link>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" loading={loading} htmlType="submit" className="user-form-button">
                            Oturum Aç
                    </Button>
                    <div>Danışan Olarak <Link to={"/kayit"}>Başvurabilirsiniz</Link></div>
                    <div>Rehber mi olmak istiyorsun? <Link to={"/kayit-mentor"}>Başvurabilirsiniz</Link></div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;
