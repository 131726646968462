import {Redirect, Route} from "react-router-dom";
import Cookies from 'js-cookie'

export const PrivateRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={({location}, props) => {
        const {token} = Cookies.getJSON()
        if (token) return <Component {...props} />
        else return <Redirect to={{pathname: "/", state: {from: location}}}/>
      }}
    />
  )
}

export const PublicRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={({location}, props) => {
        const {token} = Cookies.getJSON()
        if (!token) return <Component {...props} />
        else return <Redirect to={{pathname: "/app", state: {from: location}}}/>
      }}
    />
  )
}
