/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Dropdown, Menu } from 'antd';
import styled from "styled-components";
import { useSelector } from "react-redux"
import { BarsOutlined } from "@ant-design/icons"
import ICMPD from "../assets/images/ICMPD_logo_transparent_v2.jpg"
import ExampleSTB from "../assets/images/STB-ÖrnekLogo.png"
import ExampleEUTR from "../assets/images/EU_TR flag.png"
import YDKM from "../assets/images/YDKM_TR Yatay Renkli.png"
import uretken from "../assets/images/uretkeniz biz LOGO FINAL_LOGO VE LOGOTYPE beyaz-turkuvaz.png"
const { SubMenu } = Menu
const rightMenu = [{ name: 'Oturum Aç', path: '/giris' }, { name: 'Kaydol', path: '/kayit' }]
const CustomNav = styled(NavLink)`
color:#5887FF !important;
`
const Header = () => {
    const { pages } = useSelector(state => state.dumb)
    const { pathname } = useLocation();
    const isActive = () => {
        const isCorporate = pathname.match(/kurumsal/g)
        if (isCorporate && isCorporate.length > 0) {
            return "active-navlink"
        }
    }
    const menu = (
        <Menu>
            {pages.map(page => (
                <Menu.Item key={page.id}>
                    <CustomNav exact activeClassName="active-navlink" to={`/kurumsal/${page.id}`}>
                        {page.title}
                    </CustomNav>
                </Menu.Item>
            ))}
        </Menu>
    );
    const phoneMenu = (
        <Menu style={{ width: 200 }}>
            <SubMenu key="sub-2" title="İçerikler">
                <Menu.Item>
                    <CustomNav exact activeClassName="active-navlink" to={"/"}>Anasayfa</CustomNav>
                </Menu.Item>
                <Menu.Item>
                    <CustomNav activeClassName="active-navlink" exact to={"/statik/bloglar"}>Blog</CustomNav>
                </Menu.Item>
            </SubMenu>
            <SubMenu key="sub-1" title="Kurumsal">
                {pages.map(page => (
                    <Menu.Item key={page.id}>
                        <CustomNav exact activeClassName="active-navlink" to={`/kurumsal/${page.id}`}>
                            {page.title}
                        </CustomNav>
                    </Menu.Item>
                ))}
            </SubMenu>
            <SubMenu key="sub-3" title="Kullanıcı İşlemleri">
                {rightMenu.map(nav => (<Menu.Item key={nav.path}>
                    <CustomNav exact activeClassName="active-navlink" to={nav.path}> {nav.name} </CustomNav>
                </Menu.Item>))}
            </SubMenu>
        </Menu>
    )
    return (
        <div id="header">
            <div className="menu-items">
                <div style={{width:'95%',flexDirection:'row',display:'flex',justifyContent:'space-between'}}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <img src={ICMPD} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 75 }} alt="" />
                        <img src={uretken} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 75,width:71 }} alt="" />
                    </div>
                    <div>
                        <img src={ExampleEUTR} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 150 }} alt="" />
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>   
                        <img src={ExampleSTB} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 75 }} alt="" />
                        <img src={YDKM} className="hide-on-phone" style={{ padding: '2px 0px', objectFit: 'fill', height: 75 }} alt="" />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button type={"link"} className="hide-on-phone">
                    <NavLink exact activeClassName="active-navlink" to={"/"}>Anasayfa</NavLink>
                </Button>
                <Button type={"link"} className="hide-on-phone">
                    <Dropdown overlay={pages && pages.length > 0 ? menu : <div></div>}>
                        <a className={isActive()}>
                            Kurumsal
                        </a>
                    </Dropdown>
                </Button>
                <Button type={"link"} className="hide-on-phone">
                    <NavLink activeClassName="active-navlink" exact to={"/statik/bloglar"}>Blog</NavLink>
                </Button>

            </div>
            <div className="menu-auth hide-on-phone">
                {rightMenu.map((nav, index) => (<Button key={index}> <NavLink to={nav.path}>{nav.name}</NavLink> </Button>))}
            </div>
            <div className="menu-auth show-on-phone">
                <Dropdown overlay={phoneMenu} trigger={["click"]}>
                    <BarsOutlined onClick={(e) => e.preventDefault()} />
                </Dropdown>
            </div>
        </div>
    )
}
export default Header;
