/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { Modal } from 'antd';
import { useParams, useHistory } from "react-router-dom"
import axios from "../axios"
import Logo from "../components/FormLogo"
const MentorActivate = () => {
    const { token } = useParams();
    const history = useHistory()
    const modalTrigger = (type, message) => {
        Modal[type]({
            title: 'Bilgi',
            content: `${message}`,
            onOk() {
                history.push("/giris")
            },
        })
    }
    const activateUser = async () => {
        try {
            await axios.post(`/mentor-application-verification/${token}`)
            modalTrigger("success","E-mail bilgisi doğrulanmıştır - Başarılı")
        } catch (error) {
            modalTrigger("error","E-mail doğrulama daha önce yapılmıştır - Başarısız")
        } finally {
        }
    }
    useEffect(() => {
        activateUser()
    }, [])
    return (
        <div className="full-height full-height--center pd-15">
            <div className="user-form">
                <Logo />
            </div>
        </div>
    )
}

export default MentorActivate;