import styled from "styled-components";
import LogoSrc from '../assets/images/logo-text.png'
const Logo = styled.img`
width: 100%; 
height: auto;
margin-bottom:20px
`
Logo.defaultProps = {
    src: LogoSrc
}
export default Logo;