const SET_MENTORS = "SET_MENTORS";
const SET_SELECTED_CATEGORIES = "SET_SELECTED_CATEGORIES"
const SET_LOADING = "SET_LOADING";
const SET_Q = "SET_Q"
const LOGOUT_USER = "LOGOUT_USER";
const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES"
const SET_PAGE = "SET_PAGE"
export const set_mentors = (payload) => ({
    type: SET_MENTORS,
    payload
})
export const set_all_categories = (payload) => ({
    type: SET_ALL_CATEGORIES,
    payload
})
export const set_page = (payload) => ({
    type: SET_PAGE,
    payload
})
export const set_selected_categories = (payload) => ({
    type: SET_SELECTED_CATEGORIES,
    payload
})
export const set_q = (payload) => ({
    type: SET_Q,
    payload
})
export const set_loading = (payload) => ({
    type: SET_LOADING,
    payload
})
export const logout_user = () => ({
    type: LOGOUT_USER
})
export const initialState = {
    mentors: [],
    page: 1,
    per: 10_000,
    loading: false,
    q: null,
    mentorCategories: [],
    selectedMentorCategories: []
}
const mentors = (state = initialState, action) => {
    switch (action.type) {
        case ("SET_MENTORS"):
            return {
                ...state,
                mentors: action.payload,
            };
        case ("SET_LOADING"):
            return {
                ...state,
                loading: action.payload
            }
        case ("SET_PAGE"):
            return {
                ...state,
                page: action.payload
            }
        case ("SET_Q"):
            return {
                ...state,
                q: action.payload ? action.payload : null
            }
        case ("SET_SELECTED_CATEGORIES"):
            return {
                ...state,
                selectedMentorCategories: action.payload
            }
        case ("SET_ALL_CATEGORIES"):
            return {
                ...state,
                mentorCategories: action.payload
            }
        case ("LOGOUT_USER"):
            return initialState;
        default:
            return state
    }
}
export default mentors;