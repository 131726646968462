import React, { useState } from "react";
import { Form, Input, Button, notification, Row, Col } from "antd";
import { MailOutlined } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import axios from "../axios";
import Logo from "../components/FormLogo";

const ForgotPassword = () => {
    let history = useHistory()
    const onFinish = async () => {
        setLoading(true)
        try {
            const body = {
                email
            }
            const { data: { messages } } = await axios.post('/auth/password', body)
            notification.success({
                message: messages[0],
                placement: 'topRight',
                duration: 2
            })
            setTimeout(() => {
                history.push("/giris")
            }, 2500);
        } catch (err) {
            notification.error({
                message: err?.response?.data.messages[0] || "Bir hata oluştu",
                placement: 'topRight',
                duration: 2
            })
        } finally {
            setLoading(false)
        }
    }
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    return (
        <div className="full-height full-height--center pd-15">
            <div className="user-form">
                <Link to={"/"}>
                    <Logo />
                </Link>
                <Form
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Lütfen emailinizi kontrol ediniz!',
                                    },
                                    {
                                        required: true,
                                        message: 'Lütfen emailinizi giriniz!',
                                    },
                                ]}
                            >
                                <Input onChange={(e) => setEmail(e.target.value)} value={email} size={"large"}
                                    prefix={<MailOutlined className="site-form-item-icon" />}
                                    placeholder="Email" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" loading={loading} htmlType="submit" className="user-form-button">
                                    ŞİFREMİ SIFIRLA
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default ForgotPassword;
