import moment from "moment";
import image from "../assets/images/empty-black.jpg";
import user from "../assets/images/user.jpg";
import Cookies from "js-cookie";
export const fullname = (name, lastname) => `${name} ${lastname}`;
export const avatarFullPath = (path) =>
  `${process.env.REACT_APP_BASE_URL}/media/picture/${path}`;
export const fullLocation = (city, dist) => `${city?.name}/${dist?.name}`;
export const apiURL = process.env.REACT_APP_BASE_URL;
export const socketURL = process.env.REACT_APP_SOCKET_URL;
export const beatifulDate = (val) => moment(val).format("DD-MM-YYYY hh:mm")
export const isoDate = (val) =>
  moment(val).add(Math.abs(moment().utcOffset() / 60), "hours").toISOString().replace("Z", "");
export const dateFormat = (val) => moment(val).toDate();
export const imageErrorHandler = (e, type = null) =>
  (e.target.src = !type ? image : user);
export const dateRangeWithFormatter = (start_date, end_date) =>
  `${moment(start_date).format("DD.MM.YYYY")} ${moment(start_date).format(
    "HH:mm"
  )}-${moment(end_date).format("HH:mm")}`;
export const filterReplacer = {
  WAITING: "beklemededir",
  APPROVE: "onaylanmıştır",
  REJECT: "reddedilmiştir",
  DONE: "tamamlanmıştır",
  RESEND: "yeniden gönderilmiştir.",
  DELETE: "silinmiştir."
};
export const businessTypes = {
  individual_company: "Şahıs Şirketi",
  limited_company: "Limited Şirket",
  incorporated_company: "Anonim Şirket",
  cooperative: "Kooperatif",
  company_at_idea_stage: "Şirket Fikir Aşamasında",
  individual_application: "Şirket Bulunmuyor/Bireysel Başvuru",
}
export const faqTypes = {
  help: {
    text: 'Yardım',
    color: 'magenta'
  },
  user: {
    text: 'Danışanlar',
    color: 'purple'
  },
  mentor: {
    text: 'Rehberler',
    color: 'volcano'
  },
}
export const phoneRules = [{
    pattern: /^(?:\d*)$/,
    message: "Geçerli bir numara giriniz",
  },
  {
    required: true,
    message: "Lütfen numaranızı giriniz.",
  },
  {
    len: 11,
    message: "Numaranız 11 haneli olmalıdır.",
  },
];

export const phoneRulesNotRequired = [{
  pattern: /^(?:\d*)$/,
  message: "Geçerli bir numara giriniz",
},
{
  len: 11,
  message: "Numaranız 11 haneli olmalıdır.",
},
];

export const phoneSplitter = (phone) => `${phone.slice(0,4)}-${phone.slice(4,7)}-${phone.slice(7,9)}-${phone.slice(9, 11)}`
export const crossRouteHandlerForContracts = (id) => {
  const {
    token
  } = Cookies.getJSON()
  if (id === 'sss') {
    return token ? '/app/sss' : '/merak-edilenler/sss'
  }
  return token ? `/app/sozlesmeler/${id}` : `/sozlesmeler/${id}`
}

export const sleep = (timeout) => new Promise((res) => setTimeout(res, timeout))
export const openWithNewWindow = (id) => window.open(`${process.env.REACT_APP_SOCKET_URL}/${id}`, '_blank')
export const lowercase = {
  pattern : (/[a-zşçöüği]+/g),
  message: 'Şifreniz en az bir küçük harf içermelidir.'
}
export const uppercase = {
  pattern: (/[A-ZŞÇÖÜĞİ]+/),
  message: 'Şifreniz en az bir büyük harf içermelidir.'
};
export const excludeSpecialCharacters = {
  pattern: /[!@#$()%^&*_.]+/g,
  message: 'Şifreniz özel karakter içermelidir.(!@#$%^&*_.)'
}
export const digitRules = {
  pattern: /[\d]{1}/g,
  message: 'Şifreniz en az 1 adet rakam içermelidir.'
}
export const minimumCharacter = {
  pattern: /^.{8,}$/,
  message: 'Minimum 8 karakter girmelisiniz'
}
export const requiredPassword = {
  message: 'Lütfen bu alanı doldurunuz.',
  required: true
}