import React, { useState, useRef, useEffect } from "react";
import InnerWrapper from "../../components/InnerWrapper";
import { useSelector, useDispatch } from "react-redux";
import {
  Collapse,
  Col,
  Row,
  Upload,
  Form,
  Button,
  Input,
  Cascader,
  notification,
  Select,
  InputNumber,
  Divider,
} from "antd";
import { getBase64, beforeUpload } from "../../utils/upload.js";
import { MailOutlined, UserOutlined, EditOutlined } from "@ant-design/icons";
import axios from "../../axios/";
import styled from "styled-components";
import user from "../../assets/images/user.jpg";
import SubSector from "../../components/SubSector"
import { phoneRules, phoneRulesNotRequired, lowercase, uppercase, excludeSpecialCharacters, minimumCharacter, digitRules } from "../../utils/helpers";

const { Panel } = Collapse;
const CustomUpload = styled(Upload)`
  height: 100px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%) !important;
  width: 100px;
  border-radius: 50%;
`;
const firstSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 4,
  xl: 3,
};
const secondSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 20,
  xl: 21,
};
const settings = {
  xxl: 12,
  xl: 12,
  lg: 12,
  sm: 24,
  md: 12,
  xs: 24,
};
const { Option } = Select;
const Main = () => {
  const imageRef = useRef();
  const form = useRef();
  const inputRef = useRef();
  const { me, cities, role } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [old_password, setOldPassword] = useState("")
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [avatar, setAvatar] = useState("");
  const [phone, setPhone] = useState("");
  // eslint-disable-next-line
  const [sectors, setSectors] = useState([]);
  const [business_type, setBusinesstype] = useState("");
  const [business_name, setBusinessname] = useState("");
  const [business_role, setBusinessrole] = useState("");
  const [business_working_time, setBusinessworkingtime] = useState("");
  const [business_website, setBusinessWebsite] = useState("");
  const [business_group, setBusinessGroup] = useState("");
  const [business_adress, setBusinessAddress] = useState("");
  const [business_city, setBusinessCity] = useState("");
  const [business_phone, setBusinessPhone] = useState("");
  const [business_general, setBusinessGeneral] = useState("");
  const [business_sector, setBusinesssector] = useState("");
  const [businessstatus, setBusinessStatus] = useState(false);
  const [gender, setGender] = useState("")
  const fullname = `${me.name} ${me.lastname}`;
  const [disabled, setDisabled] = useState(true);
  const subRoute = [
    { name: "Profil", id: 1 }
  ];
  const [activeKey, setActiveKey] = useState(1);
  const [education, setEducation] = useState("");
  const [education_detail, setEducationDetail] = useState("");
  const [educationText, setEducationText] = useState("Eğitim Detay");
  const [linkedin, setLinkedin] = useState("");
  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");
  const [_categories, _setCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [globalSectors, setGlobalSectors] = useState([])
  const [disability, setDisability] = useState("")
  const [language, setLanguage] = useState("")
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const resetter = () => {
    const {
      name,
      lastname,
      email,
      city,
      district,
      avatar,
      address,
      about,
      education,
      education_detail,
      linkedin,
      phone,
      business_type,
      business_name,
      business_role,
      business_working_time,
      business_sectors,
      business_website,
      business_group,
      business_adress,
      business_city,
      business_phone,
      business_general,
      categories,
      user_sectors,
      language,
      gender,
      disability
    } = me;
    setDisability(disability)
    setPassword("");
    setPasswordConfirmation("");
    setName(name);
    setGender(gender)
    setLastname(lastname);
    setEmail(email);
    setCity(city);
    setDistrict(district);
    setAvatar(avatar);
    setLinkedin(linkedin);
    setEducation(education);
    setEducationDetail(education_detail);
    setAbout(about);
    setAddress(address);
    setPhone(phone);
    setBusinesstype(business_type);
    setBusinessname(business_name);
    setBusinessrole(business_role);
    setBusinessworkingtime(business_working_time);
    setBusinesssector(business_sectors && business_sectors.length > 0 ? business_sectors.map(s => ({sector: s.sector, sub_sector: s.sub_sector.map(x => x.id)})) : []);
    onChangeBusinesstype(business_type);
    setOldPassword("")
    setGlobalSectors(user_sectors && user_sectors.length > 0 ? user_sectors.map(s => ({...s, sub_sector: s.sub_sector.map(x => x.id)})) : [])
    _setCategories(categories && categories.length > 0 ? categories.map(i => i.id) : [])
    setLanguage(language && language.length > 0 ? language : [])
    setBusinessAddress(business_adress)
    setBusinessCity(business_city ? business_city.id : "")
    setBusinessPhone(business_phone)
    setBusinessGeneral(business_general)
    setBusinessWebsite(business_website)
    setBusinessGroup(business_group)
    form.current.setFieldsValue({
      name,
      lastname,
      business_sector: business_sectors,
      email,
      old_password: "",
      password: "",
      password_confirmation: "",
      location: [city?.id, district?.id],
      avatar,
      address,
      about,
      education,
      education_detail,
      linkedin,
      phone,
      business_type,
      business_name,
      business_role,
      business_working_time,
      categories: categories && categories.length > 0 ? categories.map(i => i.id) : [],
      language: language && language.length > 0 ? language : [],
      gender,
      disability,
      business_website,
      business_group,
      business_adress,
      business_city: business_city ? [business_city.id] : "",
      business_phone,
      business_general,
    });
  };
  const getCategories = async () => {
    try {
      const {
        data: { data },
      } = await axios.get("categories");
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeEducation = (e) => {
    if (e === "other") {
      setEducation(e);
      setEducationText("Eğitim Bilgileriniz");
    } else {
      setEducation(e);
      setEducationText("Okul ve Bölüm Bilgileriniz");
    }
  };
  const getCities = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await axios.get("cities", { params: { districts: true } });
      dispatch({ type: "SET_CITIES", payload: items });
    } catch (error) {
      console.log(error);
    }
  };

  const initialReqs = async () => {
    await getCities();
  };

  const getSectors = async () => {
    try {
      const { data: { data: { items } } } = await axios.get("sectors");
      const { data: { data } } = await axios.get('/subsectors')
      const localeSector = items.map(s => ({ ...s, children: [] }))
      setSectors(localeSector.map(sector => ({ ...sector, children: data.items.filter(s => s.sector.id === sector.id) })));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    resetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, form, activeKey, role]);
  useEffect(() => {
      getCategories()
      getSectors();
      initialReqs();
        // eslint-disable-next-line
  }, []);
  const onChangeLocation = (e) => {
    const [city, district] = e;
    setCity(city);
    setDistrict(district);
  };
  const customRequest = async (options) => {
    const { file } = options;
    getBase64(file, (image) => {
      setAvatar(image);
      updateUser(image);
    });
  };
  const updateUser = async (
    image = null,
    _body = {
      name,
      lastname,
      email,
      city: city.id ? city.id : city,
      district: district.id ? district.id : district,
      about,
      phone,
      address,
      linkedin,
      education,
      education_detail,
      user_sectors: globalSectors.map(g => ({ sector: g.sector.id, sub_sector: g.sub_sector, sector_experience: g.sector_experience })),
      business_sectors: business_sector && business_sector.length > 0 ? business_sector.map(g => ({ sector: g.sector.id, sub_sector: g.sub_sector })) : [],
    }
  ) => {
    try {
      setLoading(true);
      const body = {
        ..._body,
      };
      if (image) {
        body.avatar = image.split(",")[1];
      }
      if (password) {
        body.old_password = old_password
        body.password = password;
        body.password_confirmation = password_confirmation;
      }
      if (role === "USER") {
        body.gender = gender;
        body.disability = disability
        body.business_sectors = business_sector && business_sector.length > 0 ? business_sector.map(g => ({ sector: g.sector.id, sub_sector: g.sub_sector })) : []
        body.business_type = business_type ? business_type : "";
        body.business_name = business_name ? business_name : "";
        body.business_role = business_role ? business_role : "";
        body.business_working_time = business_working_time ? `${business_working_time}` : ""
        body.business_website = business_website
        body.business_group = business_group
        body.business_adress = business_adress
        body.business_city = business_city
        body.business_phone = business_phone
        body.business_general = business_general
      }
      else {
        body.categories = _categories
        body.language = language
      }
      const {
        data: { data },
      } = await axios.patch("update-profile", body);
      const _data = {
        ...data,
        avatar: `${process.env.REACT_APP_BASE_URL}/media/picture/${data.avatar}`,
      };
      setPassword("");
      setPasswordConfirmation("");
      notification.success({
        message: "Bilgileriniz başarıyla güncellenmiştir.",
        placement: "topRight",
        duration: 2,
      });
      setLoading(false);
      inputRef.current.focus();
      dispatch({ type: "SET_USER", payload: _data });
      setDisabled(true);
    } catch (error) {
      imageErrorHandler();
      resetter();
      notification.error({
        message: "Bir hata oluştu",
        placement: "topRight",
        duration: 2,
      });
    }
  };
  const onChangeBusinesstype = (e) => {
    if (e === "individual_application") {
      setBusinessStatus(false);
      setBusinesstype(e);
    } else {
      setBusinessStatus(true);
      setBusinesstype(e);
    }
  };
  const imageErrorHandler = () => {
    imageRef.current.src = user;
  };
  const onFinish = () => {
    const user_sectors = globalSectors.map(g => ({ sector: g.sector.id, sub_sector: g.sub_sector, sector_experience: g.sector_experience }))
    const body = {
      name,
      lastname,
      email,
      city: city.id ? city.id : city,
      district: district.id ? district.id : district,
      address: address || "",
      about,
      phone,
      linkedin: linkedin || "",
      education,
      education_detail,
      user_sectors,
    };
    if (role === "USER") {
      body.gender = gender;
      body.disability = disability
      body.business_sectors = business_sector && business_sector.length > 0 ? business_sector.map(g => ({ sector: g.sector.id, sub_sector: g.sub_sector })) : []
      body.business_type = business_type ? business_type : "";
      body.business_name = business_name ? business_name : "";
      body.business_role = business_role ? business_role : "";
      body.business_working_time = business_working_time ? `${business_working_time}` : ""
      body.business_website = business_website ? business_website : ""
      body.business_group = business_group ? business_group : ""
      body.business_adress = business_adress ? business_adress : ""
      body.business_city = business_city ? business_city : ""
      body.business_phone = business_phone ? business_phone : ""
      body.business_general = business_general ? business_general : ""
    }
    updateUser(null, body);
  };
  return (
    <InnerWrapper maxWidth={"1200px"}>
      <div className="overflow-hidden-m">
      <Row gutter={[12, 12]} style={{ padding: "20px 0px" }}>
        <Col {...firstSettings}>
          {subRoute.map((item) => (
            <div
              key={item.id}
              className={`profile-route  ${activeKey === item.id && "profile-route-bolder"
                }`}
              onClick={() => setActiveKey(item.id)}
            >
              {" "}
              <div
                className="profile-route-active"
                style={{
                  backgroundColor: activeKey === item.id ? "#5887FF" : "#fff",
                }}
              ></div>{" "}
              {item.name}{" "}
            </div>
          ))}
        </Col>
        <Col {...secondSettings}>
          <div className="profile-card">
            <CustomUpload
              valuePropName={avatar}
              showUploadList={false}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              maxCount={1}
            >
              <img
                className="profile-avatar"
                ref={imageRef}
                onError={(e) => imageErrorHandler(e)}
                src={`${avatar}?${new Date().toISOString()}`}
                alt="Profile"
              />
              <Button
                type="primary"
                icon={<EditOutlined />}
                className="avataredit"
              />
            </CustomUpload>
            <div className="profile-contain">
              <div className="profile-name"> {fullname} </div>
              <div className="profile-id">Hesap Kimlği: {me.email} </div>
            </div>
          </div>
          <Collapse
            defaultActiveKey={["1"]}
            accordion
            style={{width: '100%'}}
            activeKey={activeKey}
            onChange={(e) => setActiveKey(parseInt(e))}
          >
            <Panel header="Profil" key="1">
              <div>
                <Form ref={form} onFinish={onFinish}>
                  <Row gutter={24} justify="end">
                    <Col {...settings}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            type: "string",
                            required: true,
                            message: "Lütfen adınızı giriniz!",
                          },
                        ]}
                      >
                        <Input
                          ref={inputRef}
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          size={"large"}
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Ad"
                        />
                      </Form.Item>
                    </Col>
                    <Col {...settings}>
                      <Form.Item
                        name="lastname"
                        rules={[
                          {
                            type: "string",
                            required: true,
                            message: "Lütfen soyadınızı giriniz!",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) => setLastname(e.target.value)}
                          value={lastname}
                          size={"large"}
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Soyad"
                        />
                      </Form.Item>
                    </Col>
                    <Col {...settings}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Lütfen emailinizi kontrol ediniz!",
                          },
                          {
                            required: true,
                            message: "Lütfen emailinizi giriniz!",
                          },
                        ]}
                      >
                        <Input
                          onCopy={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          size={"large"}
                          prefix={
                            <MailOutlined className="site-form-item-icon" />
                          }
                          placeholder="Email"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col {...settings}>
                      <Form.Item
                        name="location"
                        rules={[
                          {
                            required: true,
                            message: "Lütfen şehir/ilçe seçiniz.",
                          },
                        ]}
                      >
                        <Cascader
                          fieldNames={{
                            label: "name",
                            value: "id",
                            children: "districts",
                          }}
                          showSearch={{
                            filter(inputValue, path) {
                              return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                            }
                          }}
                          placeholder="Şehir/İlçe Seçin"
                          onChange={onChangeLocation}
                          size="large"
                          options={cities}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item hasFeedback rules={[
                        {
                          required: !!password,
                          message: "Eski şifrenizi giriniz!"
                        }]} name="old_password">
                        <Input
                          onCopy={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          type="password"
                          disabled={disabled}
                          placeholder="Eski Şifreniz"
                          value={old_password}
                          onChange={(e) => setOldPassword(e.target.value)}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item hasFeedback rules={[minimumCharacter,
                        digitRules,
                        uppercase,
                        lowercase,
                        excludeSpecialCharacters]} name="password">
                        <Input
                          onCopy={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          type="password"
                          disabled={disabled}
                          placeholder="Şifreniz"
                          suffix={
                            <Button
                              type="primary"
                              onClick={() => setDisabled(!disabled)}
                              icon={<EditOutlined />}
                            />
                          }
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="password_confirmation"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: !!password,
                            message: "Şifre Tekrarı Giriniz!"
                          },
                          minimumCharacter,
                          digitRules,
                          uppercase,
                          lowercase,
                          excludeSpecialCharacters,
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Şifreler Eşleşmiyor!'));
                            },
                          }),
                        ]}
                      >
                        <Input
                          onCopy={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false
                          }}
                          type="password"
                          value={password_confirmation}
                          disabled={disabled}
                          onChange={(e) => setPasswordConfirmation(e.target.value)}
                          size={"large"}
                          placeholder="Şifre Tekrar"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="about"
                        rules={[
                          {
                            required: true,
                            message: "Boş Geçilemez",
                          },
                        ]}
                      >
                        <Input.TextArea
                          size="large"
                          value={about}
                          onChange={(e) => setAbout(e.target.value)}
                          placeholder="Bize biraz kendinizden bahseder misiniz?"
                        ></Input.TextArea>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="address">
                        <Input.TextArea
                          value={address}
                          size="large"
                          onChange={(e) => setAddress(e.target.value)}
                          placeholder="Adresiniz.."
                        ></Input.TextArea>
                      </Form.Item>
                    </Col>
                    <Col {...settings}>
                      <Form.Item
                        name="education"
                        rules={[
                          {
                            required: true,
                            message: "Lütfen Eğitim Durumu Seçiniz!",
                          },
                        ]}
                      >
                        <Select
                          value={education}
                          size="large"
                          onChange={(e) => onChangeEducation(e)}
                          placeholder="Eğitim Durumu"
                        >
                          <Option value="high_school">Lise</Option>
                          <Option value="college">Yüksekokul</Option>
                          <Option value="bachelor_degree">Lisans</Option>
                          <Option value="master_degree">Yüksek Lisans</Option>
                          <Option value="phd">Doktora</Option>
                          <Option value="other">Diğer</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col {...settings}>
                      <Form.Item
                        name="education_detail"
                        rules={[
                          {
                            type: "string",
                            required: true,
                            message: "Lütfen Eğitim Bilgilerini giriniz!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          onChange={(e) => setEducationDetail(e.target.value)}
                          value={education_detail}
                          placeholder={educationText}
                        />
                      </Form.Item>
                    </Col>

                    <Col {...settings}>
                      <Form.Item name="linkedin">
                        <Input
                          size="large"
                          onChange={(e) => setLinkedin(e.target.value)}
                          value={linkedin}
                          placeholder="LinkedIn Profil Url"
                        />
                      </Form.Item>
                    </Col>
                    <Col {...settings}>
                      <Form.Item name="phone" rules={phoneRules}>
                        <Input
                          maxLength={11}
                          placeholder="Tel No 05333333333"
                          size="large"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="globalsector" rules={[{
                        validator: (_, _a) =>
                          globalSectors.length > 0 ? Promise.resolve() : Promise.reject(new Error('Lütfen sektör seçiniz')),
                      }]}>
                        <SubSector items={globalSectors} onChange={(e) => setGlobalSectors(e)} />
                      </Form.Item>
                    </Col>
                    
                    {role &&
                      role === "USER" ? (
                      <>
                        <Col span={24}>
                          <Divider>Cinsiyet ve Engel Durumları</Divider>
                        </Col>
                        <Col {...settings}>
                          <Form.Item
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: "Lütfen Cinsiyet Seçiniz!",
                              },
                            ]}
                          >
                            <Select value={gender} onChange={(e) => setGender(e)} placeholder="Cinsiyet">
                              <Option value="female">Kadın</Option>
                              <Option value="male">Erkek</Option>
                              <Option value="other">Belirtmek İstemiyorum</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col {...settings}>
                        <Form.Item
                            name="disability"
                            rules={[
                              {
                                required: true,
                                message: "Lütfen Engel Durumu Seçiniz!",
                              },
                            ]}
                          >
                            <Select
                              value={disability}
                              onChange={(e) => setDisability(e)}
                              placeholder="Bir Engel Durumunuz Var mı? "
                            >
                              <Option value="no">Hayır</Option>
                              <Option value="yes">Evet</Option>
                              <Option value="other">Belirtmek İstemiyorum</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Divider>İşletme Bilgileri</Divider>
                        </Col>
                        <Col {...settings}>
                          <Form.Item
                            name="business_type"
                            rules={[
                              {
                                required: true,
                                message: "Lütfen İşletme Türü Seçiniz!",
                              },
                            ]}
                          >
                            <Select
                              value={business_type}
                              onChange={(e) => onChangeBusinesstype(e)}
                              placeholder="İşletme Türü "
                            >
                              <Option value="individual_company">
                                Şahıs Şirketi
                              </Option>
                              <Option value="limited_company">
                                Limited Şirket
                              </Option>
                              <Option value="incorporated_company">
                                Anonim Şirket
                              </Option>
                              <Option value="cooperative">Kooperatif</Option>
                              <Option value="company_at_idea_stage">
                                Şirket Fikir Aşamasında
                              </Option>
                              <Option value="individual_application">
                                Şirket Bulunmuyor/Bireysel Başvuru
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col {...settings}>
                          <Form.Item
                            name="business_name"
                            rules={[
                              {
                                type: "string",
                                required: businessstatus,
                                message: "Lütfen İşletme Adını giriniz!",
                              },
                            ]}
                          >
                            <Input
                              value={business_name}
                              onChange={(e) => setBusinessname(e.target.value)}
                              placeholder="İşletme Adı"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="business_role"
                            rules={[
                              {
                                type: "string",
                                required: businessstatus,
                                message:
                                  "Lütfen İşletmede Görev Tanımını Giriniz!",
                              },
                            ]}
                          >
                            <Input
                              onChange={(e) => setBusinessrole(e.target.value)}
                              value={business_role}
                              placeholder="İşletmedeki Göreviniz"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="business_sector"
                            rules={[
                              {
                                required: businessstatus,
                                message: "Lütfen İşletme Sektörü Seçiniz.",
                              },
                            ]}
                          >
                            <SubSector hide business items={business_sector} onChange={(e) => setBusinesssector(e)} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="business_working_time"
                            rules={[
                              {
                                required: businessstatus,
                                message: "İşletme kuruluş yılı giriniz.",
                              },
                              {
                                validator: (_, value) => {
                                  return (value <= currentYear && value >= 1900) || !businessstatus ? Promise.resolve() : Promise.reject(`Kuruluş yılınız 1900-${currentYear} yılları arasında olmak zorundadır.`)
                                } 
                              }
                            ]}
                          >
                            <InputNumber type="number" style={{ width: '100%' }} placeholder="İşletme kuruluş yılı" value={business_working_time} onChange={(e) => setBusinessworkingtime(e)} />
                          </Form.Item>
                        </Col>
                        <Col {...settings}>
                          <Form.Item name="business_website">
                            <Input
                              onChange={(e) => setBusinessWebsite(e.target.value)}
                              value={business_website}
                              placeholder="İşletme websitesi URL"
                            />
                          </Form.Item>
                        </Col>
                        <Col {...settings}>
                          <Form.Item name="business_group">
                            <Input
                              onChange={(e) => setBusinessGroup(e.target.value)}
                              value={business_group}
                              placeholder="İşletme Grup"
                            />
                          </Form.Item>
                        </Col>
                        <Col {...settings}>
                          <Form.Item name="business_adress">
                            <Input
                              onChange={(e) => setBusinessAddress(e.target.value)}
                              value={business_adress}
                              placeholder="İşletme Adres"
                            />
                          </Form.Item>
                        </Col>
                        <Col {...settings}>
                          <Form.Item name="business_city">
                            <Cascader
                              value={business_city}
                                fieldNames={{
                                  label: "name",
                                  value: "id",
                                }}
                                showSearch={{
                                  filter(inputValue, path) {
                                    return path.some(
                                      (option) =>
                                        option.name
                                          .toLowerCase()
                                          .indexOf(inputValue.toLowerCase()) > -1
                                    );
                                  },
                                }}
                                placeholder="İşletme Şehir Seçin"
                                onChange={(e) => setBusinessCity(e[0])}
                                options={cities}
                                onClear={() => setBusinessCity("")}
                            />
                          </Form.Item>
                        </Col>
                        <Col {...settings}>
                        <Form.Item name="business_phone" rules={phoneRulesNotRequired}>
                            <Input
                              maxLength={11}
                              placeholder="İşletme Tel No 05333333333"
                              value={business_phone}
                              onChange={(e) => setBusinessPhone(e.target.value)}
                            />
                          </Form.Item>
                        </Col>
                        <Col {...settings}>
                          <Form.Item name="business_general">
                            <Input
                              onChange={(e) => setBusinessGeneral(e.target.value)}
                              value={business_general}
                              placeholder="İşletme Genel Bilgiler"
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={24}>
                          <Form.Item
                            name="categories"
                            rules={[
                              {
                                required: true,
                                message: "Lütfen uzmanlık alanları seçiniz.",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="Uzmanlık Alanlarını Seçiniz"
                              value={_categories}
                              onChange={(e) => _setCategories(e)}
                            >
                              {categories?.map((cat) => (
                                <Option key={cat.id} value={cat.id}>
                                  {" "}
                                  {cat.title}{" "}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                              name="language"
                              rules={[
                                {
                                  required: true,
                                  message: "Lütfen dil seçiniz.",
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="İletişim Dillerini Seçiniz"
                                value={language}
                                onChange={(e) => setLanguage(e)}
                              >
                                <Option key="tr" value="tr">
                                  {" "}
                                  Türkçe{" "}
                                </Option>
                                <Option key="en" value="en">
                                  {" "}
                                  İngilizce{" "}
                                </Option>
                                <Option key="de" value="de">
                                  Almanca
                                </Option>
                                <Option key="fr" value="fr">
                                  Fransızca
                                </Option>
                                <Option key="ar" value="ar">
                                  Arapça
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                      </>
                    )}
                    <Col>
                      <Form.Item>
                        <Button
                          type="primary"
                          loading={loading}
                          htmlType="submit"
                        >
                          KAYDET
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      </div>
    </InnerWrapper>
  );
};
export default Main;
