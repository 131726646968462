import React, { useRef, useState } from "react";
import { Carousel } from "antd"
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import styled from "styled-components"
import st from "../assets/images/landing-1.jpg"
import nd from "../assets/images/landing-2.jpg"
import td from "../assets/images/landing-3.jpg"
const CarouselWrapper = styled.div`
position:relative;
padding:0px 15px;
`
const Slider = ({ children, ...rest }) => {
    const [index, setIndex] = useState(0)
    const slider = useRef(null)
    const bgChanger = (index) => {
        const arr = [st,nd,td];
        const { isImage } = rest;
        if(isImage) {
            return {
                "backgroundImage":`url(${arr[index]})`,
                "backgroundSize": "cover",
                "backgroundPosition": "center",
                "height": "calc(100vh - 400px)"
            }
        }
    }
    return (
        <CarouselWrapper style={bgChanger(index)} className="overflow-hidden">
            <CaretLeftFilled className="carousel__arrow arrow--prev" onClick={() => slider.current.prev()} />
            <Carousel afterChange={(slide) => setIndex(slide)} ref={slider} {...rest}>
                {children}
            </Carousel>
            <CaretRightFilled className="carousel__arrow arrow--next" onClick={() => slider.current.next()} />
        </CarouselWrapper>
    )
}
Slider.defaultProps = {
    autoplay: 2500,
    dots: true,
    infinite: false,
    isImage: false,
    effect: "scrollX"
}
export default Slider;