import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const Preloader = () => {
  const icon = <LoadingOutlined style={{fontSize: 24}} spin/>;

  return <Spin indicator={icon}/>;
};

export default Preloader;
