import React, { useEffect, useState, useRef } from 'react'
import InnerWrapper from '../components/InnerWrapper'
import Calendar from '../components/Calendar'
import { Modal, Button, notification, Input, Form, Tag } from 'antd'
import { dateFormat, isoDate, filterReplacer, openWithNewWindow } from '../utils/helpers'
import axios from '../axios'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import moment from 'moment'
const colors = {
  APPROVE: {
    bg: 'green',
    color: '#fff'
  },
  REJECT: {
    bg: 'red',
    color: '#fff'
  },
  DONE: {
    bg: 'pink',
    color: '#000'
  },
  WAITING: {
    bg: 'blue',
    color: '#fff'
  },
  SET_BY_MENTOR_APPOINTMENT: {
    bg: 'purple',
    color: '#fff'
  },
  RESEND: { bg: 'yellow', color: '#000' }
}
const { TextArea } = Input
const MyCalendar = () => {
  const form = useRef()
  const [open, setOpen] = useState(false)
  const { role } = useSelector(state => state.user)
  const [availability, setAvailability] = useState([])
  const [show, setShow] = useState(false)
  const [currentEvent, setEvent] = useState('')
  const [loading, setLoading] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const [availabilityWithFully, setAvailabilityWithFully] = useState([])
  const [reason, setReason] = useState('')
  const [step, setStep] = useState(0)
  const [item, setItem] = useState()
  const [title, setTitle] = useState('')
  const [email, setEmail] = useState('')
  const [event_place, setEventPlace] = useState('')
  const [webinarLoading, setWebinarLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [visiters, setVisiters] = useState([])
  const disabled = visiters.length === 0 || !title || !description || !event_place

  const getEvents = async () => {
    try {
      const {
        data: {
          data: { availability, individual_appointments }
        }
      } = await axios.get(`/calendar`, {
        params: { role: role || Cookies.get('roles') }
      })
      const full = [].concat(
        ...individual_appointments.map(item => {
          if (role === 'MENTOR') {
            return item.mentor_availability.map(x => ({
              ...item,
              ...x,
              id: item.id,
              mentor_decision: item.type === 'SET_BY_MENTOR_APPOINTMENT' ? item.type : item.mentor_decision,
              title: item.type === 'SET_BY_MENTOR_APPOINTMENT' ? `${item.title} Etkinlik/Toplantı` : item.title
            }))
          } else return item
        }),
        ...availability.filter(item => !item.is_reserved).map(item => ({ ...item, title: 'Görüşmeye uygun' }))
      )
      setAvailabilityWithFully(full)
      const events = full.map(t => {
        const end = moment(t.end_date).format('HH:mm')
        const isEnd =
          end === '00:00'
            ? moment(t.end_date)
                .add(Math.abs(moment().utcOffset() / 60), 'hours')
                .subtract(1, 'second')
                .toISOString()
                .replace('Z', '')
            : t.end_date
        return {
          id: t.id,
          start_date: dateFormat(t.start_date),
          end_date: dateFormat(isEnd),
          title: !t.title ? `${t.with_who_name} ${t.with_who_lastname}(${filterReplacer[t.mentor_decision]})` : t.title
        }
      })
      setAvailability(events)
    } catch (error) {
      notification.error({ message: error.response.data.messages[0] })
    }
  }

  const onFinishEmail = e => {
    if (e.keyCode === 13) {
      if (!visiters.includes(e.target.value)) {
        setVisiters([...visiters, e.target.value])
        setEmail('')
        form.current.resetFields()
      }
    }
  }

  useEffect(() => {
    getEvents()
    // eslint-disable-next-line
  }, [role])

  const multipleSelection = items => {
    const filteredAvailability = availabilityWithFully
      .filter(item => item.title === 'Görüşmeye uygun')
      .map(({ start_date, end_date }) => ({ start_date, end_date }))
    const valid = items
      .map(item => {
        const start_time = moment(item.start_date).format('HH:mm')
        return {
          start_date: item.start_date.slice(0, -4),
          end_date:
            start_time === '23:30'
              ? isoDate(moment(item.start_date).add('minutes', 30)).slice(0, -4)
              : item.end_date.slice(0, -4)
        }
      })
      .every(item => filteredAvailability.some(f => JSON.stringify(f) === JSON.stringify(item)))

    const datas = availabilityWithFully.filter(item =>
      items.some(f => {
        const start_time = moment(item.start_date).format('HH:mm')
        return (
          JSON.stringify({
            start_date: f.start_date.slice(0, -4),
            end_date:
              start_time === '23:30'
                ? isoDate(moment(f.start_date).add('minutes', 30)).slice(0, -4)
                : f.end_date.slice(0, -4)
          }) === JSON.stringify({ start_date: item.start_date, end_date: item.end_date })
        )
      })
    )
    return { valid, datas }
  }

  const onSelectSlot = val => {
    const { slots, start, end } = val
    const slotCount = Math.ceil((moment(end) - moment(start)) / (1000 * 60 * 30))

    const items = []
    Array.from(Array(slotCount).keys()).forEach(slot => {
      const start_date = slot === 0 ? isoDate(moment(start)) : items[slot - 1].end_date
      const start_time = moment(start_date).format('HH:mm')
      const end_date = isoDate(moment(start_date).add('minutes', start_time === '23:30' ? 29 : 30))
      items.push({ start_date, end_date })
    })
    const [start_date] = slots
    if (isValidTime(start_date)) return

    const { valid, datas } = multipleSelection(items)

    if (valid) {
      setOpen(true)
      setStep(1)
      setItem(datas)
      return
    }

    Modal.confirm({
      title: 'UYARI!',
      content: 'Geçerli aralığı müsait olarak ayarlamak istediğinize emin misiniz?',
      okText: 'EVET',
      cancelText: 'İPTAL',
      onOk: () => handleMeet(items)
    })
  }
  const isValidTime = start => new Date(start).getTime() < new Date().getTime()
  const handleMeet = async values => {
    try {
      const body = {
        available_dates: values,
        unavailable_dates: []
      }
      await axios.put('/mentor-availability', body)
      await getEvents()
    } catch (error) {
      notification.error({ message: error.response.data.messages[0] })
    }
  }
  const eventStyleGetter = (event, ...rest) => {
    const _event = availabilityWithFully.find(a => a.id === event.id)
    var style = {
      backgroundColor: colors[_event?.mentor_decision]?.bg,
      borderRadius: '0px',
      opacity: 0.8,
      color: colors[_event?.mentor_decision]?.color,
      border: '0px',
      display: 'block'
    }
    return {
      style: style
    }
  }
  const removeEvent = async (item, { id, mentor_availability, ...rest }) => {
    try {
      setInnerLoading(true)
      const body = {
        available_dates: [],
        unavailable_dates: [{ ...item }]
      }
      await axios.put(`/mentor-availability`, body)
      setOpen(false)
      setInnerLoading(false)
      await getEvents()
    } catch (error) {
      setInnerLoading(false)
      notification.error({ message: error.response.data.messages[0] })
    }
  }
  const onSelectEvent = val => {
    const isUser = role === 'USER'
    const { start_date, end_date, mentor_decision, title, room_id, ...rest } = availabilityWithFully.find(
      ava => ava.id === val.id
    )
    setEvent({ start_date, end_date, mentor_decision, room_id, ...rest })
    if (title === 'Görüşmeye uygun' && !isUser) {
      const item = availabilityWithFully.find(ava => ava.id === val.id)
      setOpen(true)
      setItem({ item, id: val.id })
    } else setShow(true)
  }
  const mentorHandlerMeets = async mentor_decision => {
    try {
      setLoading(true)
      const { id, mentor_availability, type } = currentEvent
      if (mentor_decision !== 'delete')
        await axios.patch(`/appointment/${id}`, {
          mentor_availability: mentor_availability.map(i => i.id),
          mentor_decision,
          type
        })
      else {
        if (reason !== '')
          await axios.post(`/appointment/${id}`, {
            reason: reason
          })
        else {
          notification.error({
            message: 'Reddetme işleminde açıklama alanı boş geçilemez!',
            duration: 1
          })
          return
        }
      }
      await getEvents()
      notification.success({
        message: 'İşleminiz başarıyla gerçekleşmiştir.',
        duration: 1
      })
      setShow(false)
      setEvent('')
    } catch (error) {
      notification.error({ message: error.response.data.messages[0] })
    } finally {
      setLoading(false)
    }
  }
  const disableHandler = type => {
    if (!currentEvent) return false
    const isUser = role === 'USER'
    const { mentor_decision } = currentEvent
    if (isUser) {
      if (mentor_decision === 'APPROVE') return type !== 'JOIN' && type !== 'DELETE'
      // else if (mentor_decision === "WAITING") return type !== "REJECT" && type !== "APPROVE"
      else return type !== 'DELETE'
    } else {
      if (mentor_decision === 'APPROVE') return type !== 'JOIN' && type !== 'DELETE'
      else if (mentor_decision === 'WAITING') return type === 'JOIN'
      else if (mentor_decision === 'REJECT') return type !== 'DELETE'
      else if (mentor_decision === 'SET_BY_MENTOR_APPOINTMENT') return type !== 'JOIN'
    }
  }
  const EventDescription = () => {
    const description = 'Bu görüşme için hangi işlemi yapmak istiyorsunuz ?'
    return <div style={{ marginBottom: 24 }}> {description} </div>
  }

  const handleSubmit = async () => {
    try {
      const body = {
        mentor_availability: Array.isArray(item) ? item.map(i => i.id) : [item.id],
        email_list: visiters,
        event_place,
        title,
        description
      }
      setWebinarLoading(true)
      await axios.put('/set-by-mentor-appointment', body)
      setOpen(false)
      setTitle('')
      setDescription('')
      setEventPlace('')
      form.current.resetFields()
      setEmail('')
      setWebinarLoading(false)
      getEvents()
    } catch (error) {
      notification.error({
        message: error.response.data.messages[0],
        placement: 'topRight',
        duration: 2
      })
    }
  }

  const resetHandle = () => {
    setEmail('')
    setTitle('')
    setDescription('')
    setVisiters([])
    setStep(0)
  }

  const WebinarTitle = () => (
    <div style={{ display: 'flex', gap: 4 }}>
      {!Array.isArray(item) && (
        <div
          style={{
            color: step === 0 && '#5887FF',
            fontWeight: step === 0 && 'bold',
            cursor: 'pointer',
            borderRight: '1px solid #000',
            paddingRight: 6
          }}
          onClick={() => setStep(0)}
        >
          Müsaitlik Durumu Kaldırma
        </div>
      )}
      <div
        style={{ color: step === 1 && '#5887FF', fontWeight: step === 1 && 'bold', cursor: 'pointer' }}
        onClick={() => setStep(1)}
      >
        Etkinlik/Toplantı
      </div>
    </div>
  )

  return (
    <InnerWrapper>
      <Calendar
        eventPropGetter={eventStyleGetter}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        events={availability}
      />
      <Modal footer={null} title="Görüşme Detayları" closable keyboard onCancel={() => setShow(false)} visible={show}>
        <EventDescription />
        <div
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
            paddingBottom: 20
          }}
        >
          <TextArea
            name="reasontext"
            onChange={e => setReason(e.target.value)}
            placeholder="Lütfen İşlem Açıklaması Giriniz"
            size={'small'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <Button onClick={() => mentorHandlerMeets('delete')} loading={loading} danger>
              İPTAL/RED
            </Button>
          </div>
          <div>
            <Button
              disabled={disableHandler('APPROVE')}
              loading={loading}
              onClick={() => mentorHandlerMeets('approve')}
            >
              ONAYLA
            </Button>{' '}
            <Button
              disabled={disableHandler('JOIN')}
              loading={loading}
              onClick={() => openWithNewWindow(currentEvent.room_id)}
              type="primary"
            >
              KATIL
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={open}
        afterClose={() => resetHandle()}
        destroyOnClose
        title={<WebinarTitle />}
        footer={null}
        closable
        keyboard
        onCancel={() => {
          setOpen(false)
          resetHandle()
        }}
      >
        {step === 0 ? (
          <div>
            <div>
              <div>Müsaitlik durumunu kaldırmak istediğinize emin misiniz?</div>
            </div>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button onClick={() => setOpen(false)} type="text">
                İPTAL
              </Button>
              <Button
                loading={innerLoading}
                disabled={innerLoading}
                onClick={() =>
                  removeEvent(
                    {
                      start_date: isoDate(item.item.start_date),
                      end_date: isoDate(item.item.end_date)
                    },
                    item.id
                  )
                }
                type="primary"
              >
                EVET
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Form>
              <Form.Item
                name="name"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'Lütfen etkinlik başlığını giriniz!'
                  }
                ]}
              >
                <Input
                  onChange={e => setTitle(e.target.value)}
                  value={title}
                  size={'large'}
                  placeholder="Etkinlik Başlığı"
                />
              </Form.Item>
              <Form.Item
                name="description"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'Lütfen etkinlik açıklamasını giriniz!'
                  }
                ]}
              >
                <TextArea
                  onChange={e => setDescription(e.target.value)}
                  value={description}
                  size={'large'}
                  rows={3}
                  placeholder="Etkinlik Açıklaması"
                />
              </Form.Item>
              <Form.Item
                name="place"
                rules={[
                  {
                    type: 'string',
                    message: 'Lütfen etkinlik yerini giriniz.',
                    required: true
                  }
                ]}
              >
                <Input
                  onChange={e => setEventPlace(e.target.value)}
                  value={event_place}
                  size={'large'}
                  placeholder="Etkinlik Yeri"
                />
              </Form.Item>
            </Form>
            <Form ref={form}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Lütfen emailinizi kontrol ediniz',
                    required: visiters.length === 0
                  }
                ]}
              >
                <Input
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  size={'large'}
                  placeholder="Email"
                  onKeyDown={e => onFinishEmail(e)}
                />
              </Form.Item>
              {visiters.map((visiter, index) => (
                <Tag onClose={() => setVisiters(visiters.filter(item => item !== visiter))} closable key={index}>
                  {visiter}
                </Tag>
              ))}
            </Form>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button type="text" onClick={() => setOpen(false)}>
                İPTAL
              </Button>
              <Button disabled={disabled || webinarLoading} onClick={() => handleSubmit()} type="primary">
                KAYDET
              </Button>
            </div>
          </>
        )}
      </Modal>
    </InnerWrapper>
  )
}
export default MyCalendar
