import world from "../assets/images/world-map.png"
import styled from "styled-components"
export const MapContainer = styled.div`
display:flex;
flex-flow:column nowrap;
position:relative;
height:400px;
background: rgba(0,221,191, .5);
`
export const Map = styled.div`
top:0;
left:0;
right:0;
bottom:0;
position:absolute;
background-image: url(${props => props.url});
width: 100%;
opacity:0.50;
mix-blend-mode: luminosity;
background-position: 100% 20px;
background-repeat: no-repeat;
background-attachment: fixed;
will-change: transform;
`
export const MapContent = styled.div`
display:flex;
flex-flow:column nowrap;
flex:1;
justify-content:center;
z-index:2;
h1 {
    display:flex;
    align-items:flex-start;
    color:#fff;
    font-weight:bold;
}
`
export const MapContentWrapper = styled.div`
max-width:1400px;
margin:0 auto;
width:100%;
display:flex;
flex-flow:column nowrap;
padding:0px 20px;
div {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:35px;
    font-weight:bold;
    color:#fff;
}
`
Map.defaultProps = {
    url: world
}