import { useState, useRef, useEffect, useCallback } from "react"
import InnerWrapper from "../components/InnerWrapper"
import {
    Map,
    MapContent,
    MapContentWrapper,
    MapContainer,
} from "../components/InnerLanding"
import {
    MentorContainer,
} from "../components/Mentor";
import { UploadOutlined, CalendarOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { getBase64, beforeUpload, dummyRequest } from "../utils/upload.js";
import { Form, Upload, Row, Col, Input, Button, notification, Pagination, Card } from "antd"
import axios from "../axios";
import { NoData } from "../components/NoData";
import { avatarFullPath, beatifulDate, imageErrorHandler } from "../utils/helpers"
const settings = {
    xxl: 12,
    xl: 12,
    lg: 12,
    sm: 24,
    md: 24,
    xs: 24,
}
const settingsV2 = {
    xxl: 24,
    xl: 24,
    lg: 24,
    sm: 24,
    md: 24,
    xs: 24,
}
const Support = () => {
    const ref = useRef()
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [images, setImages] = useState([])
    const [requests, setRequests] = useState([])
    const [total, setTotal] = useState(1)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const onFinish = async () => {
        if (loading) return;
        try {
            setLoading(true)
            const body = {
                title,
                content,
                pictures: images.length > 0 ? images.map(image => image.split(",")[1]) : [],
            }
            const { data: { messages } } = await axios.post('/support', body)
            notification.success({
                message: messages,
                placement: "topRight",
                duration: 2,
            })
            clear()
            if(page !== 1) setPage(1)
            else getSupportRequests()
        } catch (error) {
            const { response: { data: { messages } } } = error
            const [message] = messages
            notification.error({
                message,
                placement: "topRight",
                duration: 2,
            })
        } finally {
            setLoading(false)
        }
    }
    const clear = () => {
        setTitle("")
        setContent("")
        setImages([])
        ref.current.resetFields()
    }
    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) => {
                setImages([...images, imageUrl])
            });
        }
    };
    const getSupportRequests = useCallback(async () => {
        try {
            const { data: { data: { items, pagination: { total } } } } = await axios.get('/support', { params: { page, per: 5 } })
            setRequests(items)
            setTotal(total)
        } catch (error) {

        }
    }, [page])
    useEffect(() => {
        getSupportRequests()
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [getSupportRequests, page])
    return (
        <InnerWrapper>
            <MapContainer>
                <MapContent>
                    <MapContentWrapper>
                        <div>Kaviyer DESTEK!</div>
                        <div style={{ fontSize: 20 }}>Buradan problemlerinizi bizimle paylaşabilirisiniz.</div>
                    </MapContentWrapper>
                </MapContent>
                <Map></Map>
            </MapContainer>
            <MentorContainer>
                <Form ref={ref} onFinish={onFinish}>
                    <Row gutter={24}>
                        <Col {...settings}>
                            <Col {...settingsV2}>
                                <h1>Geçmiş Destek Talepleriniz</h1>
                                {requests.length === 0 && <NoData title="Geçmiş Destek Talebiniz Bulunmamaktadır." />}
                                {requests.map((req, index) => <Req req={req} key={index} />)}
                                {total > 5 && <Pagination current={page} pageSize={5} onChange={(page) => setPage(page)} total={total} />}
                            </Col>
                        </Col>
                        <Col {...settings}>
                            <Col>
                                <h1>Bizimle iletişime geçebilirsiniz!</h1>
                            </Col>
                            <Col {...settingsV2}>
                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            type: "string",
                                            required: true,
                                            message: "Bu alan zorunludur.",
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => setTitle(e.target.value)}
                                        value={title}
                                        size={"large"}
                                        placeholder="Başlık"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...settingsV2}>
                                <Form.Item
                                    name="content"
                                    rules={[
                                        {
                                            type: "string",
                                            required: true,
                                            message: "Bu alan zorunludur.",
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        onChange={(e) => setContent(e.target.value)}
                                        value={content}
                                        autoSize={{minRows: 6}}
                                        size={"large"}
                                        placeholder="Açıklama"
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...settingsV2}>
                                <Form.Item name="images">
                                    <Upload
                                        maxCount={3}
                                        valuePropName={images}
                                        value={images}
                                        listType="picture"
                                        defaultFileList={[]}
                                        customRequest={dummyRequest}
                                        onChange={handleChange}
                                        beforeUpload={beforeUpload}
                                        className="upload-list-inline"
                                    >
                                        <Button icon={<UploadOutlined />}>Dosya Yükle (Maks 3)</Button>
                                        
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col style={{ marginTop: 25 }} {...settingsV2}>
                                <Button loading={loading} disabled={loading} type="primary" htmlType="submit">GÖNDER</Button>
                            </Col>
                        </Col>

                    </Row>
                </Form>
            </MentorContainer>
        </InnerWrapper>
    )
}
const Req = ({ req }) => {
    const { title, content, pictures, is_read, created_at } = req;
    return (
        <Card style={{ marginBlock: 10 }}>
            <div style={{ marginLeft: 5, fontSize: 14, fontWeight: 'bold' }}> {title} </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>{pictures && pictures.length > 0 && pictures.map((p, k) => <img key={k} style={{ borderRadius: 5, objectFit: 'cover', width: 'calc(25% - 10px)', height: 100, margin: 5 }} src={avatarFullPath(p)} alt="" onError={imageErrorHandler} />)}</div>
            <div style={{ fontSize: 12, marginLeft: 5 }}> {content} </div>
            <div style={{display: 'flex',fontSize: 13, flexDirection:'row', justifyContent: 'space-between', marginLeft: 5, flexWrap: 'nowrap', overflow: 'auto'}}>
                <div> <CalendarOutlined /> {beatifulDate(created_at)} </div>
                <div> Görüldü: {is_read ? <CheckCircleOutlined style={{color:"green"}} /> : <CloseCircleOutlined style={{color:"red"}} />} </div>
            </div>
        </Card>
    )
}
export default Support