import React from "react";
import styled from "styled-components"
import { Landing } from "./Landing"
import { CaretRightFilled } from '@ant-design/icons';
const LandingDocumentWrapper = styled(Landing)`
    padding:40px 20px;
    max-width:1600px;
    margin: 0 auto;
`
const LandingDocumentHeader = styled.div`
display:flex;
flex-flow:row nowrap;
justify-content:space-between;
align-items:center;
padding: 0 25px;
h1 {
    text-transform:uppercase;
    font-size:24px;
    color:rgba(0, 0, 0, 0.85);
    padding:10px 0px;
    position:relative;
    div {
        position:absolute;
        bottom:0;
        left:0;
        width:50px;
        border-bottom:1px solid #5887FF;
    }
}
`
export const LandingDocumentButton = styled.button`
display:flex;
align-self:center;
justify-content:center;
color:${props => props.outline ? '#5887FF' : '#fff'};
border:${props => props.outline ? '1px solid #5887FF' : '#fff'};
outline:none;
cursor:pointer;
background:${props => !props.outline ? '#5887FF' : '#fff'};
text-transform:uppercase;
align-items:center;
border-radius:3px;
padding:5px 10px;
&:hover {
    background:${props => props.outline ? '#5887FF' : '#5887FF'};
    color:${props => !props.outline ? '#fff' : '#fff'};
    transition:500ms all;
}
span{
    margin-left:5px;
}
`
LandingDocumentButton.defaultProps = {
    hide:false,
    outline: true
}
export const LandingDocument = ({ children, routeHandler, ...rest }) => {
    const { title, fullscreen, hide } = rest;
    return (
        <LandingDocumentWrapper fullscreen={fullscreen}>
            <LandingDocumentHeader>
                <h1>
                    {title}
                    <div></div>
                </h1>
                {!hide && <LandingDocumentButton onClick={() => routeHandler()}>Tümünü Gör <CaretRightFilled /> </LandingDocumentButton>}
            </LandingDocumentHeader>
            {children}
        </LandingDocumentWrapper>
    )
}
