import styled from "styled-components";
export const Landing = styled.div`
    height: ${props => !props.fullscreen ? 'calc(100vh - 60px)' : 'block'};
`
export const LandingInner = styled.div`
    display:flex;
    flex-flow:column wrap;
    align-items:flex-end;
    padding:20px 60px;
`
export const LandingText = styled.div`
    font-size:50px;
    color:#fff;
    padding:0px 10px;
    display:flex;
    flex-flow:row nowrap;
    background:#00ddbf;
    margin-bottom:10px;
    @media only screen and (max-width: 1024px) {
        font-size: 24px;
    }
`
export const LandingButton = styled.button`
    background:#5887FF;
    cursor:pointer;
    border:none;
    padding:15px 25px;
    color:#fff;
    display:flex;
    align-items:center;
    font-size:25px;
    span {
        margin-left:10px
    }
`
export const LandingImage = styled.div`
display:flex;
flex:1;
height:450px;
flex-flow:column nowrap;
background-image: url(${props => props.url});
background-size: cover;
background-position: center;
justify-content:center;
div {
    flex:1;
    justify-content:center;
    background:rgba(0,0,0,.5);
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    h1 {
        font-size:40px;
        color:#fff;
    }
    p {
        font-weight:500;
        color:#fff;
        font-size:20px;
    }
}
`
export const LandingComment = styled.div`
background:#f5f5f5;
height:220px;
border-radius:3px;
.comment-header {
    background:#fff;
    padding:15px 15px 15px 110px;
    display:flex;
    font-size:16px;
    font-weight:500;
    color:#666;
    align-items:center;
    flex:1;
}
.comment-content {
    padding:10px 0px;
    img {
        position:absolute;
        border:5px solid #fff;
        height:100px;
        width:100px;
        object-fit:cover;
        border-radius:50%;
        top:-5px;
    }
    div {
        font-style:italic;
        font-size:15px;
        color:#666;
        margin-top:40px;
        padding-left: 92px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

    }
}
`
Landing.defaultProps = {
    fullscreen: false
}