import styled from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
const Layout = styled.div`
  width:100%;
  margin:0px auto;
  padding:150px 0px 0px 0px;
`
const OuterLayout = ({ children}) => {
  return (
    <>
      <Header />
      <Layout>
        {children}
      </Layout>
      <Footer />
    </>
  )
}
export default OuterLayout;