import React from "react"
import InnerWrapper from "../components/InnerWrapper";
import MentorRegister from "../pages/MentorRegister"
import { useSelector } from "react-redux"
const InnerMentorRegister = () => {
    const { me } = useSelector(state => state.user)
    return (
        <InnerWrapper>
            <MentorRegister user={me} disabled/>
        </InnerWrapper>
    )
}
export default InnerMentorRegister;