import React, { useState, useEffect } from 'react'
import InnerWrapper from "../components/InnerWrapper"
import {
    Map,
    MapContent,
    MapContentWrapper,
    MapContainer,
} from "../components/InnerLanding";
import {
    MentorContainer,
} from "../components/Mentor";
import { Tabs, Button, Row, Col, Modal, Input, Form, notification, Card } from 'antd';
import axios from "../axios"
import {
    DeleteOutlined, UserOutlined, CalendarOutlined, CheckCircleOutlined, CloseCircleOutlined, CommentOutlined
} from "@ant-design/icons";
import { NoData } from '../components/NoData';
import { fullname, beatifulDate } from '../utils/helpers';
const settings = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
};
const { TabPane } = Tabs;
const Messages = () => {
    const [activeKey, setActiveKey] = useState("1")
    const [sendedMessages, setSendedMessages] = useState([])
    const [inbox, setInbox] = useState([])
    useEffect(() => {
        function getMessages() {
            axios.get('/messages').then(res => {
                const { data: { data: { received_messages, send_messages } } } = res;
                setSendedMessages(send_messages)
                setInbox(received_messages)
            })
        }
        getMessages()
    }, [])
    const inboxReadHandler = (_id) => {
        setInbox(inbox.map(({id, from_user_is_read, ...rest}) => {
            return id === _id ? ({id, from_user_is_read: true, ...rest}) : ({id, from_user_is_read, ...rest})
        }))
    }
    return (
        <InnerWrapper>
            <MapContainer>
                <MapContent>
                    <MapContentWrapper>
                        <div>Kaviyer'e HOŞGELDİNİZ!</div>
                        <div style={{ fontSize: 20 }}>Buradan mesajlarını inceleyebilirsin.</div>
                    </MapContentWrapper>
                </MapContent>
                <Map></Map>
            </MapContainer>
            <MentorContainer>
                <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={(e) => setActiveKey(e)}>
                    <TabPane tab="Gelen Kutusu" key="1">
                        <Row gutter={[24, 24]}>{inbox.length > 0 ? inbox.map((message, key) =>
                            <Col key={key} {...settings}><Message readSuccess={(e) => inboxReadHandler(e)} type="inbox" removeSuccess={(id) => setInbox(inbox.filter(s => s.id !== id))} message={message} /></Col>) :
                            <Col {...settings}><NoData title="Gelen kutusu boş." /></Col>}
                        </Row>
                    </TabPane>
                    <TabPane tab="Gönderilenler" key="2">
                        <Row gutter={[24, 24]}>{sendedMessages.length > 0 ? sendedMessages.map((message, key) =>
                            <Col key={key} {...settings}><Message removeSuccess={(id) => setSendedMessages(sendedMessages.filter(s => s.id !== id))} message={message} /></Col>) :
                            <Col {...settings}><NoData title="Gönderilenler boş." /></Col>}
                        </Row>
                    </TabPane>
                </Tabs>
            </MentorContainer>
        </InnerWrapper>
    )
}
const Message = ({ message, removeSuccess, readSuccess, type = 'sent' }) => {
    const user = type === 'sent' ? message.message_to : message.message_from
    const messageUser = `${fullname(user.name, user.lastname)}`
    const messageDate = beatifulDate(message.send_at)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [replyMessage, setReplyMessage] = useState("")
    const confirm = () => {
        Modal.confirm({
            icon: <CloseCircleOutlined />,
            content: 'Mesajı silmek istediğinize emin misiniz?',
            okText: 'SİL',
            onOk: () => removeMessage(),
            cancelText: 'İPTAL',
        });
    }
    const removeMessage = async () => {
        try {
            await axios.delete(`/messages/${message.id}`)
            removeSuccess(message.id)
        } catch (error) {

        }
    }
    const onFinish = async () => {
        const { message_from: { id } } = message
        try {
            const body = {
                title: message.title,
                content: replyMessage,
                message_to: id,
            }
            setLoading(true)
            setShow(false)
            await axios.put('/messages', body)
            notification.success({
                message: "Mesajınız başarıyla iletilmiştir.",
                placement: "topRight",
                duration: 2,
              });
        } catch (error) {
            notification.error({
                message: "Bir şeyler ters gitti 😥",
                placement: "topRight",
                duration: 2,
              });
        }finally {
            setLoading(false)
        }
    }
    const readHandler = async () => {
        const { id } = message;
        try {
            await axios.patch(`/messages/read/${id}`)
            readSuccess(id)
        } catch (error) {
            
        }
    }
    return (
        <Card>
            <div className="message">
                <div className="message-wrapper">
                    <div className="message-main">
                        <div className="message-title"> {message.title} </div>
                        <div className="message-content"> {message.content} </div>
                    </div>
                    <div className="message-actions">
                        {type === 'inbox' ? message.from_user_is_read ? <Button style={{marginRight: 5}}><CheckCircleOutlined title="Okundu" /></Button> : <Button style={{marginRight: 5}} title="Okunmadı" icon={<CloseCircleOutlined />} onClick={() => readHandler()}  /> : null}
                        {type !== 'sent' && <Button type="ghost" style={{marginRight: 5}} onClick={() => setShow(true)} icon={<CommentOutlined />} size="middle" />}
                        <Button type="ghost" onClick={() => confirm()} icon={<DeleteOutlined />} size="middle" />
                    </div>
                </div>
                <div className="message-info">
                    <div className="message-user"> <UserOutlined /> {messageUser} </div>
                    <div className="message-user"> <CalendarOutlined /> {messageDate} </div>
                </div>
            </div>
            <Modal centered
                closable
                width={400}
                onCancel={() => setShow(false)}
                visible={show}
                destroyOnClose={true}
                footer={null}>
                <h1>Mesajı Yanıtla!!</h1>
                <Form onFinish={onFinish}>
                    <Form.Item name="reply"
                        rules={[
                            {
                                type: "string",
                                required: true,
                                message: "Lütfen yanıtınızı giriniz.",
                            },
                        ]}>
                        <Input.TextArea
                            onChange={(e) => setReplyMessage(e.target.value)}
                            value={replyMessage}
                            placeholder="Mesaja yanıtınız..."
                            size={"large"} />
                    </Form.Item>
                    <Button loading={loading} disabled={loading} onClick={() => onFinish()}>YANITLA</Button>
                </Form>
            </Modal>
        </Card>
    )
}
export default Messages
