import Android from "../assets/images/playstore.png";
import Ios from "../assets/images/appstore.png";
import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "../axios";
import { crossRouteHandlerForContracts } from "../utils/helpers";

const Footer = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const { pages } = useSelector((state) => state.dumb);
  const [contracts, setContracts] = useState([]);
  const getDumbs = async (type) => {
    try {
      const {
        data: { data },
      } = await axios.get(`${type}`);
      type === "contracts" ? setContracts(data) : setContracts(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getDynamicPages = async () => {
      try {
        const {
          data: { data },
        } = await axios.get("/pages");
        dispatch({ type: "SET_PAGES", payload: data });
      } catch (error) { }
    };
    getDynamicPages();
    getDumbs("contracts");
  }, [dispatch]);
  return (
    <>
      <div id="footer">
        <Row>
          <Col span={6}>
            <h3 className="footerh3">Hızlı Erişim</h3>
            <ul className="footerul">
              {pages &&
                pages.length > 0 &&
                pages.map((page) => <li onClick={() => history.push(`/kurumsal/${page.id}`)} key={page.id}> {page.title} </li>)}
            </ul>
          </Col>
          <Col span={6}>
            <h3 className="footerh3">Sözleşmeler</h3>
            <ul className="footerul">
              {contracts.map((_contracts, index) => (
                <li key={index}>
                  <Link
                    to={crossRouteHandlerForContracts(_contracts.id)}
                    style={{ color: "white" }}
                  >
                    {_contracts.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col span={6}>
            <ul className="footerul">
              <li className="footerh3">
                <Link to={crossRouteHandlerForContracts('sss')} style={{ color: 'white' }}>
                  Sıkça Sorulan Sorular
                </Link>
              </li>
            </ul>
          </Col>
          <Col span={6}>
            <div className="stores">
              <div>
                <img src={Android} alt="Playstore" />
              </div>
              <div>
                <img src={Ios} alt="Appstore" />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="copyright">© Kaviyer 2021 - All rights reserved</div>
          </Col>
        </Row>
      </div>
      <div style={{ backgroundColor: '#da291c', display: 'flex', alignItems: 'center', padding: 15, color: 'white' }}>
      Bu Hibe Programı, Avrupa Birliği'nin “Türkiye'deki Mülteciler için Mali Yardım Programı (FRIT)” tarafından fonlanan ve Uluslararası Göç Politikaları Geliştirme Merkezi (ICMPD) tarafından uygulanan “Sürdürülebilir Sosyo-Ekonomik Uyum için Girişimcilik Kapasitelerinin Geliştirilmesi (ENHANCER)” (IPA/ 2019/411-568) projesi kapsamında finanse edilmektedir.
      </div>
    </>
  );
};

export default Footer;
