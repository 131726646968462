import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { notification, Col, Row } from "antd";
import { dateRangeWithFormatter } from "../utils/helpers";
const SelectableItem = styled.div`
  padding: 2.5px 3px;
  margin: 2.5px;
  background: ${(props) =>
    props.disable ? "#ccc" : props.isActive ? "#e6fffb" : "#fff"};
  border: 1px solid #87e8de;
  color: #08979c;
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
`;
const CustomRow = styled(Row)`
max-height: 300px;
justify-content:space-between;
overflow-y: auto;
`
const settings = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12
}
const Selectable = ({ reset, items, onSelect, selected }) => {
    const [localSelect, setLocalSelect] = useState(selected);
    const itemHandler = ({ id, is_reserved, ...rest }) => {
        if (disableHandler({ id, is_reserved, ...rest })) return;
        else {
            if (localSelect.length === 0)
            setLocalSelect([...localSelect, { id, ...rest }]);
            else
            localSelect.some((s) => s.id === id)
            ? setLocalSelect(localSelect.filter((l) => l.id !== id))
            : localSelect.length < 4
            ? setLocalSelect([...localSelect, { id, ...rest }])
            : notification.error({
                message: "Uyarı",
                duration: 1,
                description: "Maksimum 4 tarih aralığı seçebilirsiniz.",
            });
        }
    };
  const rule = Math.round(items.length / 2);
  const disableHandler = (item) =>
    item.is_reserved || new Date(item.start_date) < new Date();
  const isActive = (id) => localSelect.some((s) => s.id === id);
  useEffect(() => {
    onSelect(localSelect);
  }, [localSelect, onSelect, selected]);
  useEffect(() => {
    setLocalSelect([]);
  }, [reset]);
  return (
    <CustomRow>
      <Col {...settings}>
        {items.slice(0, rule).map((item) => (
          <SelectableItem
            onClick={() => itemHandler(item)}
            isActive={isActive(item.id)}
            disable={disableHandler(item)}
            key={item.id}
          >
            {" "}
            {dateRangeWithFormatter(item.start_date, item.end_date)}{" "}
          </SelectableItem>
        ))}
      </Col>
      <Col {...settings}>
        {items.slice(rule, items.length).map((item) => (
          <SelectableItem
            onClick={() => itemHandler(item)}
            isActive={isActive(item.id)}
            disable={disableHandler(item)}
            key={item.id}
          >
            {" "}
            {dateRangeWithFormatter(item.start_date, item.end_date)}{" "}
          </SelectableItem>
        ))}
      </Col>
    </CustomRow>
  );
};
export default Selectable;
