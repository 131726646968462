import React, { useState, useEffect } from "react";
import InnerWrapper from "../../components/InnerWrapper";

import {
  Map,
  MapContent,
  MapContentWrapper,
  MapContainer,
} from "../../components/InnerLanding";
import {
  MentorSearchOuter,
  MentorSearch,
  MensorSearchCol,
  MentorContainer,
} from "../../components/Mentor";
import { useSelector } from "react-redux";
import {
  CheckOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import {
  Spin,
  Row,
  Col,
  Select,
  Form,
  Input,
  Space,
  Card,
  Rate,
  Modal,
  notification,
  Button,
} from "antd";
import { NoData } from "../../components/NoData";
import {
  fullname,
  dateRangeWithFormatter,
  filterReplacer,
  openWithNewWindow,
} from "../../utils/helpers";
import axios from "../../axios";
import Cookies from "js-cookie";
import MentorModal from "../../components/MentorModal";
const settings = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
  xl: 6,
};
const typeReplacer = {
  MEET: "TANIŞMA",
  LESSON: "GÖRÜŞME",
};
const Mark = styled.div`
  color: #5887ff;
  display: inline-flex;
  font-weight: 500;
  margin-right: 5px;
`;
const MentorMain = () => {
  const { role } = useSelector((state) => state.user);
  const [filter, setFilter] = useState(null);
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);
  const [meets, setMeets] = useState([]);
  const [currentMeet, setCurrentMeet] = useState("");
  const [reason, setReason] = useState("");
  const [show, setShow] = useState(false);
  const filters = [
    { name: "Onaylanmış", value: "APPROVE" },
    { name: "Beklemede", value: "waiting" },
    { name: "Tamamlanmış", value: "DONE" },
  ];
  const getRequests = async () => {
    try {
      setLoading(true);
      const {
        data: {
          data: { items },
        },
      } = await axios.get("/appointments", {
        params: { status: filter, role: role || Cookies.get("roles") },
      });
      setMeets(items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const deleteHandler = async () => {
    try {
      setInnerLoading(true);
      const { id } = currentMeet;
      const body = { reason };
      await axios.post(`/appointment/${id}`, body);
      notification.success({
        message: "Başarılı.",
        placement: "topRight",
        duration: 2,
      });
      getRequests();
      setShow(false);
      setCurrentMeet("");
    } catch (error) {
      notification.error({
        message: error.response.data.messages[0],
        placement: "topRight",
        duration: 2,
      });
    } finally {
      setInnerLoading(false);
    }
  };
  const handler = (mentor_decision, meet) => {
    const content =
      mentor_decision === "delete"
        ? "Bu etkinliği silmek istediğinize eminmisiniz"
        : "Bu etkinliği kabul etmek istediğinize emin misiniz ?";
    if (mentor_decision === "approved") {
      openWithNewWindow(meet.room_id);
      return true;
    } else if (mentor_decision === "approve") {
      Modal.confirm({
        title: "Dikkat!!",
        content,
        onOk: async () => {
          try {
            const body = {
              mentor_availability: meet.mentor_availability.map((i) => i.id),
              mentor_decision,
            };
            await axios.patch(`/appointment/${meet.id}`, body);
            notification.success({
              message: "Başarılı.",
              placement: "topRight",
              duration: 2,
            });
            getRequests();
          } catch (error) {
            notification.error({
              message: error.response.data.messages[0],
              placement: "topRight",
              duration: 2,
            });
          }
        },
        okText: mentor_decision !== "approve" ? "Evet" : "Kabul Et",
        cancelText: "İptal",
      });
    } else {
      setCurrentMeet(meet);
      setShow(true);
    }
  };
  const handleUserModal = (user) => {
    setUser(user);
    setOpen(true);
  };
  useEffect(() => {
    getRequests();
    // eslint-disable-next-line
  }, [filter]);
  useEffect(() => {
    !show && setReason("");
  }, [show]);
  return (
    <InnerWrapper backgroundColor="#f5f5f5">
      <MapContainer>
        <MapContent>
          <MapContentWrapper>
            <div>Kaviyer'e HOŞGELDİNİZ!</div>
          </MapContentWrapper>
        </MapContent>
        <Map></Map>
      </MapContainer>
      <MentorSearchOuter>
        <MentorSearch>
          <MensorSearchCol>
            <Space
              direction="vertical"
              style={{
                width: "100%",
                maxWidth: 500,
              }}
            >
              <Select
                style={{ width: "100%" }}
                defaultValue={filter}
                size="large"
                placeholder="Görüşme Durumu Seçiniz..."
                allowClear
                onChange={(val) => setFilter(val)}
              >
                {filters.map((filter, index) => (
                  <Select.Option
                    disabled={loading}
                    key={index}
                    value={filter.value}
                  >
                    {" "}
                    {filter.name}{" "}
                  </Select.Option>
                ))}
              </Select>
            </Space>
          </MensorSearchCol>
        </MentorSearch>
      </MentorSearchOuter>
      <MentorContainer>
        <h1>Görüşme Talepleriniz</h1>
        <Row gutter={[24, 24]}>
          {meets.length > 0 &&
            meets.map((meet) => (
              <Col {...settings} key={meet.id}>
                <Card
                  hoverable
                  actions={
                    meet.mentor_decision === "WAITING"
                      ? [
                          <CheckOutlined
                            onClick={() => handler("approve", meet)}
                          />,
                          <DeleteOutlined
                            disabled={true}
                            onClick={() => handler("delete", meet)}
                          />,
                        ]
                      : meet.mentor_decision === "DELETE"
                      ? [
                          <Button
                            onClick={() => handler("approve", meet)}
                            icon={<CheckOutlined />}
                            disabled={true}
                            type="Primary"
                            style={{ border: 0, background: "#FFF" }}
                          />,
                          <Button
                            onClick={() => handler("delete", meet)}
                            icon={<DeleteOutlined />}
                            disabled={true}
                            type="Primary"
                            style={{ border: 0, background: "#FFF" }}
                          />,
                        ]
                      : [
                          <Button
                            onClick={() => handleUserModal(meet.user)}
                            icon={<InfoCircleOutlined />}
                            disabled={false}
                            type="Primary"
                            style={{ border: 0, background: "#FFF" }}
                          />,
                          <Button
                            onClick={() => handler("approved", meet)}
                            icon={<CheckOutlined />}
                            disabled={false}
                            type="Primary"
                            style={{ border: 0, background: "#FFF" }}
                          />,
                          <Button
                            onClick={() => handler("delete", meet)}
                            icon={<DeleteOutlined />}
                            disabled={false}
                            type="Primary"
                            style={{ border: 0, background: "#FFF" }}
                          />,
                        ]
                  }
                >
                  <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: 'column',
                      }}
                    >
                      <div style={{marginBottom: 5}}>Danışan Performans Notu:</div>
                      <div style={{ display: 'flex' }}>
                        <Rate
                          allowHalf
                          disabled
                          style={{ fontSize: 12 }}
                          value={meet.user.score}
                        />
                        <div style={{ marginLeft: 10 }}>
                          Değerlendirme sayısı:
                          <strong style={{marginLeft: 5}}>{meet.user.score_count}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Mark>{fullname(meet.user.name, meet.user.lastname)} </Mark>
                  adlı kullanıcı sizinle {""}
                  <Mark left>
                    {dateRangeWithFormatter(meet.start_date, meet.end_date)}
                  </Mark>
                  tarihleri arasında ({typeReplacer[meet.type]}) görüşmek
                  istiyor.
                  <div>
                    Bu görüşmenin durumu :{" "}
                    {filterReplacer[meet.mentor_decision]}
                  </div>
                  <div style={{marginTop: 10}}>
                    Danışanın mesajı:{" "}
                    {meet.appointment_message
                      ? meet.appointment_message
                      : "Mesaj yok"}
                  </div>
                  <div>
                    Bu görüşmenin yeri :{" "}
                    {meet.event_place || '-'}
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
        {loading && <Spin size="large" tip="Yükleniyor..." />}
        {!loading && meets.length === 0 && (
          <NoData title="Aradığınız kritere uygun görüşme bulunamamıştır." />
        )}
        <Modal
          footer={null}
          title="Görüşme Detayları"
          closable
          keyboard
          destroyOnClose
          onCancel={() => setShow(false)}
          visible={show}
        >
          <Form onFinish={deleteHandler}>
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Lütfen iptal sebebinizi bildirin.",
                },
              ]}
            >
              <Input.TextArea
                onChange={(e) => setReason(e.target.value)}
                placeholder="Lütfen bu işlemi iptal etme nedeninizi söyler misiniz?"
                size="large"
                rows="4"
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                flexWrap: "nowrap",
              }}
            >
              <Button
                onClick={() => setShow(false)}
                style={{ marginLeft: 5 }}
                htmlType="button"
              >
                VAZGEÇ
              </Button>
              <Button
                disabled={innerLoading}
                htmlType="submit"
                loading={innerLoading}
                danger
              >
                İPTAL/RED
              </Button>
            </div>
          </Form>
        </Modal>
      </MentorContainer>
      <Modal
        footer={null}
        title="Danışan Bilgileri"
        closable
        keyboard
        destroyOnClose
        onCancel={() => setOpen(false)}
        visible={open}
      >
        <MentorModal mentor={user} isUser={true} show={false} />
      </Modal>
    </InnerWrapper>
  );
};
export default MentorMain;
