import { avatarFullPath, imageErrorHandler } from "../utils/helpers";

export const CommentContent = (comment) => {
  return (
    <div>
      <div className="comment-header">
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            onError={(e) => imageErrorHandler(e, "user")}
            src={avatarFullPath(comment.picture)}
            alt="Comment"
          />
          <div style={{ marginLeft: 10 }}>
            <div style={{ fontWeight: "bold", color: "#5887FF", fontSize: 14 }}>
              {comment.name}
            </div>
            <div style={{ color: "#5887FF", fontSize: 12 }}>
              {comment.position}
            </div>
          </div>
        </div>

        <div style={{ marginTop: 10, fontSize: 14 }}>{comment.description}</div>
      </div>
    </div>
  );
};
