import React, { useEffect, useState } from 'react'
import axios from '../axios'
import { Button, Modal, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import InnerWrapper from '../components/InnerWrapper'
import {NoData} from '../components/NoData'
import User from '../components/User'
import { useHistory } from 'react-router-dom'
const { confirm } = Modal
const Subuser = () => {
    const history = useHistory()
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
      async function getUsers() {
        try {
            setLoading(true)
            const params = { 
              page: 1,
              per: 5000000
            }
            const { data: { data: { items } } } = await axios.get('business-users/all', { params })
            setUsers(items)
        } catch (error) {
            console.log(error)
        }finally {
            setLoading(false)
        }
      }
      getUsers()    
    }, [])

    const onDelete = (id) => {
        confirm({
            title: 'Silmek istediğinize emin misiniz?',
            content: 'Bu işlem gerçekleştikten sonra geriye alamazsınız.',
            onOk() {
                return new Promise((res, rej) => {
                    axios.delete(`business-users/${id}`).then(_res => {
                        setUsers(users.filter(user => user.id !== id))
                        return res()
                    }).catch(err => {
                        return rej(err)
                    })
                })
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }
    return (
        <InnerWrapper backgroundColor="#f5f5f5">
            <div style={{padding: 25}}>
                <div style={{display: 'flex', flexFlow: 'row nowrap', backgroundColor:' #fff', alignItems:'center', justifyContent:'space-between', padding: '15px 20px', borderBottom: '1px solid #ccc'}}>
                    <h1>Firma Danışanları</h1> 
                    <Button type="primary" onClick={() => history.push('/app/kullanici-islemi')} shape="circle" icon={<PlusOutlined />} />
                </div>
                <div style={{backgroundColor:' #fff'}}>
                    {users.map((user, index) => <User user={user} onDelete={(id) => onDelete(id)} key={index} />)}
                    {(loading || users.length === 0 )&& (<div style={{display: 'flex', alignItems:'center', justifyContent: 'center', padding: 15}}>
                        {loading && <Spin size="large" tip="Yükleniyor..." />}
                        {!loading && users.length === 0 && <NoData />}
                    </div>)}
                </div>
            </div>
        </InnerWrapper>
    )
}
export default Subuser
